import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { Provider } from 'react-redux';
import store from './redux/store';
window.store = store;

function App({children}) {
    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
}

export default App;
