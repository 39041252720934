import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import { config, useSpring, animated } from "react-spring";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "../../redux/actions";
import { isMobile } from "react-device-detect";
import { useGesture } from "react-with-gesture";
import { add } from "vec-la";
import { useInView } from "react-intersection-observer";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
        minHeight: isMobile ? "510px" : "640px",
    },
    content: {
        marginTop: isMobile ? undefined : "30px",
        display: "flex",
        flexDirection: isMobile ? "column" : "row-reverse",
        width: isMobile ? "375px" : "1100px",
        justifyContent: "space-between",
        margin: "auto",
        height: isMobile ? "450px" : "636px",
    },
    mobilecontent: {
        display: "flex",
        flexDirection: "row-reverse",
        width: isMobile ? "375px" : "1000px",
        justifyContent: "space-between",
        margin: isMobile ? "20px auto" : "auto",
        height: isMobile ? "370px" : "636px",
    },
    title: {
        marginTop: "32px",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: isMobile ? "22px" : "30px",
        lineHeight: "108.1%",
        color: "#567284",
        alignSelf: isMobile ? "flex-start" : "center",
        maxWidth: isMobile ? "320px" : undefined,
    },
    prefix: {
        marginTop: isMobile ? undefined : "20px",
        marginLeft: isMobile ? "25px" : undefined,
        maxWidth: isMobile ? "320px" : undefined,
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: isMobile ? "23px" : "30px",
        lineHeight: isMobile ? "23px" : "108.1%",
        color: "#567284",
        alignSelf: isMobile ? "flex-start" : undefined,
    },
    flexGrow: {
        flexGrow: 1,
    },
    speakers: {
        width: isMobile ? "100%" : "385px",
        height: isMobile ? undefined : "500px",
        // minHeight: isMobile ? '130px' : '400px',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    speakerstext: {
        height: "50px",
        fontFamily: "Lato",
        fontWeight: "bold",
        fontSize: "30px",
        lineHeight: "25px",
        textAlign: "center",
        letterSpacing: "0.683839px",
        marginBottom: "50px",
    },
    answers: {
        width: "305px",
        height: isMobile ? "350px" : "500px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    answer: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: isMobile ? "16px" : "20px",
        lineHeight: "25px",
        textAlign: "left",
        letterSpacing: "0.6px",
        alignSelf: "left",
        color: "#307b26",
        marginLeft: isMobile ? "20px" : undefined,
    },
    slider: {
        height: isMobile ? "350px" : "500px",
        marginRight: isMobile ? "20px" : "50px",
    },
    drag: {
        width: isMobile ? "177px" : "351px",
        height: isMobile ? "54px" : "71px",
        backgroundColor: "#C73E76",
        borderRadius: isMobile ? "40px" : "59px",
        cursor: "-webkit-grab",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        color: "white",
        whiteSpace: "pre",
        boxShadow: "1px 1px 14px rgba(0, 0, 0, 0.05)",
        zIndex: 1,
    },
    dragtext: {
        fontFamily: "Lato",
        fontWeight: "600",
        fontSize: isMobile ? "16px" : "20px",
        lineHeight: isMobile ? "18px" : "108.1%",
        color: "#ffffff",
    },
    line: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        height: "100%",
        bottom: isMobile ? "54px" : "71px",
        zIndex: -10,
    },
    hr: {
        height: "98%",
        opacity: isMobile ? 0.5 : 1,
    },
    upbullet: {
        zIndex: 1,
        height: "21px",
        width: "21px",
        borderRadius: "50%",
        backgroundColor: "#C73E76",
        position: "relative",
        top: 0,
        left: isMobile ? "44%" : "47%",
    },
    downbullet: {
        height: "21px",
        width: "21px",
        borderRadius: "50%",
        backgroundColor: "#000000",
        position: "relative",
        top: "calc(100% - 21px)",
        right: isMobile ? "44%" : "47%",
    },
    newslider: {
        height: isMobile ? "350px" : "500px",
        width: isMobile ? "300px" : "500px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: isMobile ? "10px" : undefined,
    },
    newhr: {
        display: "flex",
        width: "2px",
        height: isMobile ? "60px" : "100px",
        zIndex: -10,
        borderLeft: "2px solid #c3c3c3",
    },
    bullet1: {
        height: "21px",
        width: "21px",
        borderRadius: "50%",
        backgroundColor: "#c3c3c3",
        position: "relative",
        top: 0,
        right: isMobile ? "44%" : "47%",
        cursor: "-webkit-grab",
    },
    newbullet: {
        position: "absolute",
        left: isMobile ? "calc(50% - 10px)" : "calc(50% - 250px)",
        width: isMobile ? "177px" : "351px",
        height: isMobile ? "40px" : "71px",
        borderRadius: isMobile ? "40px" : "59px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        whiteSpace: "pre",
        boxShadow: "1px 1px 14px rgba(0, 0, 0, 0.05)",
        zIndex: 1,
    },
    newbullettext: {
        fontFamily: "Lato",
        fontWeight: "600",
        fontSize: isMobile ? "12px" : "20px",
        lineHeight: "108.1%",
        color: "#ffffff",
    },
}));

const colors = [
    {
        fill: "#FCF5DE",
        stroke: "#EABA00",
        mouthD:
            "M287.957 150.511C287.957 164.925 282.231 178.75 272.038 188.943C261.846 199.135 248.021 204.862 233.606 204.862C219.192 204.862 205.367 199.135 195.174 188.943C184.982 178.75 179.255 164.925 179.255 150.511",
    },
    { fill: "#F4F7FF", stroke: "#4D64B0", mouthD: "M175.245 157.979C175.245 157.979 194.394 205.372 232.691 205.372" },
    { fill: "#DCEDDC", stroke: "#307B26", mouthD: "M183.278 190.926H284.179" },
    { fill: "#E6DDEB", stroke: "#896DA0", mouthD: "M180.278 184.926H284.179" },
    { fill: "#FFF0F6", stroke: "#C73E76", mouthD: "M175.144 179.708H290.694" },
];
const prefix = ["never happened", "maybe once", "1-2 times", "3-4 times", "5 times or more", "- -"];

export default function HeartQuestionView(props) {
    const { question, onNextReady } = props;
    const classes = useStyles();
    const [ref, inView] = useInView({
        threshold: 0,
    });
    const answer = useSelector((state) => state.main.session[question.id]);
    const dispatch = useDispatch();
    const currentVal = (answer !== undefined && question.possible_answers[answer]) || "- -";
    const currentStroke = (answer !== undefined && colors[answer].stroke) || "#C3C3C3";
    const currentFill = (answer !== undefined && colors[answer].fill) || "#FFFFFF";
    const [text, setText] = useState(prefix[5]);
    const [bodySpring, setBodySpring] = useSpring(() => ({ stroke: currentStroke, config: config.slow }));
    const [pathoneSpring, setPathoneSpring] = useSpring(() => ({ fill: "#C3C3C3", config: config.slow, opacity: 0.1 }));
    const [colorSpring, setColorSpring] = useSpring(() => ({ color: "#C73E76" }));
    const [pathtwoSpring, setPathtwoSpring] = useSpring(() => ({
        fill: "#C3C3C3",
        config: config.slow,
        opacity: 0.05,
    }));
    const [smileyAnimationSpring, setSmileyAnimationSpring] = useSpring(() => ({
        reset: true,
        from: { opacity: 1, transform: "scale(0.5, 0.5)" },
        to: { opacity: 1, transform: "scale(1, 1)" },
        config: config.wobbly,
        delay: 200,
    }));
    const [textSpring, setTextSpring] = useSpring(() => ({
        reset: true,
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: config.wobbly,
        delay: 200,
    }));
    const onBulletClick = async (idx) => {
        const nextColor = colors[idx];
        await setBodySpring({ reset: true, fill: "#ffffff", stroke: nextColor.stroke });
        await setPathoneSpring({ reset: true, fill: nextColor.fill, stroke: nextColor.stroke });
        await setPathtwoSpring({ reset: true, fill: nextColor.fill, stroke: nextColor.stroke });
        await setText(prefix[idx]);
        await setSliderColor({
            reset: true,
            backgroundColor: nextColor.stroke,
            borderLeft: `2px solid ${nextColor.stroke}`,
        });
        setColorSpring({ color: nextColor.stroke });
        await dispatch(Actions.setSessionObject({ [question.id]: idx }));
        setTimeout(async () => {
            onNextReady();
        }, 1000);
    };
    const changeColor = async (idx) => {
        const nextColor = colors[idx];
        await setBodySpring({ reset: true, fill: "#ffffff", stroke: nextColor.stroke });
        await setPathoneSpring({ reset: true, fill: nextColor.fill, stroke: nextColor.stroke });
        await setPathtwoSpring({ reset: true, fill: nextColor.fill, stroke: nextColor.stroke });
        await setText(prefix[idx]);
        await setSliderColor({
            reset: true,
            backgroundColor: nextColor.stroke,
            borderLeft: `2px solid ${nextColor.stroke}`,
        });
        setColorSpring({ color: nextColor.stroke });
    };
    const [sliderColor, setSliderColor] = useSpring(() => ({ backgroundColor: currentStroke, config: config.slow }));
    const setHeartColor = (d) => {
        if (d < 58) {
            changeColor(4);
        } else if (d < 163) {
            changeColor(3);
        } else if (d < 278) {
            changeColor(2);
        } else if (d < 393) {
            changeColor(1);
        } else {
            changeColor(0);
        }
    };
    const setHeartColorWithAnswer = (d) => {
        if (d < 58) {
            set({ xy: [0, -10], config: { duration: 200 } });
            onBulletClick(4);
        } else if (d < 163) {
            set({ xy: [0, 105], config: { duration: 200 } });
            onBulletClick(3);
        } else if (d < 278) {
            set({ xy: [0, 220], config: { duration: 200 } });
            onBulletClick(2);
        } else if (d < 393) {
            set({ xy: [0, 335], config: { duration: 200 } });
            onBulletClick(1);
        } else {
            set({ xy: [0, 450], config: { duration: 200 } });
            onBulletClick(0);
        }
    };
    const setMobileHeartColorWithAnswer = (d) => {
        if (d < 30) {
            set({ xy: [0, -10], config: { duration: 200 } });
            onBulletClick(4);
        } else if (d < 110) {
            set({ xy: [0, 70], config: { duration: 200 } });
            onBulletClick(3);
        } else if (d < 190) {
            set({ xy: [0, 150], config: { duration: 200 } });
            onBulletClick(2);
        } else if (d < 270) {
            set({ xy: [0, 230], config: { duration: 200 } });
            onBulletClick(1);
        } else {
            set({ xy: [0, 310], config: { duration: 200 } });
            onBulletClick(0);
        }
    };
    const setMobileHeartColor = (d) => {
        if (d < 30) {
            changeColor(4);
        } else if (d < 110) {
            changeColor(3);
        } else if (d < 190) {
            changeColor(2);
        } else if (d < 270) {
            changeColor(1);
        } else {
            changeColor(0);
        }
    };

    const [{ xy }, set] = useSpring(() => ({ xy: [0, isMobile ? 30 : 40] }));
    const bind = useGesture(({ down, delta, velocity, direction, temp = xy.getValue() }) => {
        if (!isMobile) {
            if (delta[1] + temp[1] < -10) {
                set({ xy: [0, 0], immediate: down, config: { mass: velocity, tension: 500 * velocity, friction: 50 } });
            } else if (delta[1] + temp[1] > 450) {
                set({
                    xy: [0, 450],
                    immediate: down,
                    config: { mass: velocity, tension: 500 * velocity, friction: 50 },
                });
            } else {
                set({
                    xy: add(delta, temp),
                    immediate: down,
                    config: { mass: velocity, tension: 500 * velocity, friction: 50 },
                });
            }
            if (!down) {
                setHeartColorWithAnswer(delta[1] + temp[1]);
            }
            setHeartColor(delta[1] + temp[1]);
        } else {
            if (delta[1] + temp[1] < -10) {
                set({ xy: [0, -10], config: { mass: velocity, tension: 500 * velocity, friction: 50 } });
            } else if (delta[1] + temp[1] > 310) {
                set({ xy: [0, 310], config: { mass: velocity, tension: 500 * velocity, friction: 50 } });
            } else {
                set({
                    xy: add(delta, temp),
                    immediate: down,
                    config: { mass: velocity, tension: 500 * velocity, friction: 50 },
                });
            }
            if (!down) {
                setMobileHeartColorWithAnswer(delta[1] + temp[1]);
            }
            setMobileHeartColor(delta[1] + temp[1]);
        }
        return temp;
    });

    useEffect(() => {
        const loadStuff = async () => {
            await setBodySpring({
                reset: true,
                fill: "#ffffff",
                stroke: currentVal === "- -" ? "#c3c3c3" : currentStroke,
            });
            await setText(currentVal);
            await setColorSpring({ color: currentVal === "- -" ? "#c3c3c3" : currentStroke });
            await setPathoneSpring({
                reset: true,
                fill: currentVal === "- -" ? "#c3c3c3" : currentFill,
                stroke: currentVal === "- -" ? "#c3c3c3" : currentStroke,
                opacity: 0.2,
            });
            await setPathtwoSpring({
                reset: true,
                fill: currentVal === "- -" ? "#c3c3c3" : currentFill,
                stroke: currentVal === "- -" ? "#c3c3c3" : currentStroke,
                opacity: 0.1,
            });
            await setSliderColor({
                reset: true,
                backgroundColor: currentVal === "- -" ? "#c3c3c3" : currentStroke,
                borderLeft: currentVal === "- -" ? "2px solid #c3c3c3" : `2px solid ${currentStroke}`,
            });
            switch (currentVal) {
                case "5 times or more":
                    set({ xy: [0, -10], config: { duration: 200 } });
                    break;
                case "3-4 times":
                    set({ xy: isMobile ? [0, 70] : [0, 105], config: { duration: 200 } });
                    break;
                case "1-2 times":
                    set({ xy: isMobile ? [0, 150] : [0, 220], config: { duration: 200 } });
                    break;
                case "Maybe once":
                    set({ xy: isMobile ? [0, 230] : [0, 335], config: { duration: 200 } });
                    break;
                case "Never Happened":
                    set({ xy: isMobile ? [0, 310] : [0, 450], config: { duration: 200 } });
                    break;
                default:
                    break;
            }
            await setSmileyAnimationSpring({
                reset: true,
                from: { opacity: 0, transform: "scale(0.1, 0.1)" },
                to: { opacity: 1, transform: "scale(1, 1)" },
                config: config.wobbly,
                delay: 150,
            });
            await setTextSpring({
                reset: true,
                from: { opacity: 0, transform: "scale(0.1, 0.1)" },
                to: { opacity: 1, transform: "scale(1, 1)" },
                config: config.wobbly,
                delay: 250,
            });
        };
        if (inView) loadStuff();
    }, [question, inView]);

    return (
        <div className={classes.root} ref={ref}>
            <Typography className={classes.prefix}>{question.props.prefix}</Typography>
            <div className={classes.content}>
                <animated.div className={classes.speakers} style={colorSpring}>
                    {!isMobile && <animated.p className={classes.speakerstext}> {text} </animated.p>}
                    <animated.svg
                        style={smileyAnimationSpring}
                        width={isMobile ? "162" : "364"}
                        height={isMobile ? "110" : "226"}
                        viewBox="0 0 162 111"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <animated.path
                            style={pathoneSpring}
                            d="M45.4832 6.44604C61.5425 1.13429 74.7728 11.6465 79.8379 18.7279C89.961 10.5909 121.225 -4.84863 132.244 26.1922C144.949 61.9856 99.6506 97.781 81.0418 105.526C8.69873 67.685 21.9124 14.2423 45.4832 6.44604Z"
                            stroke="#DEF0DE"
                            strokeWidth="9.7531"
                        />
                        <animated.path
                            style={pathtwoSpring}
                            d="M50.8616 14.4567C64.4629 9.98669 75.6681 18.833 79.9579 24.7922C88.5316 17.9447 115.011 4.95189 124.342 31.0737C135.103 61.1949 96.7381 91.3178 80.9775 97.8357C19.7074 65.9911 30.8986 21.0175 50.8616 14.4567Z"
                            stroke="#EFFFEF"
                            strokeWidth="9.7531"
                        />
                        <animated.path
                            style={bodySpring}
                            d="M79.8292 92.8917C155.955 53.1957 108.771 -5.42761 80.7464 31.6084L80.2368 32.4269L79.6254 31.6084C51.6004 -5.42761 5.0476 53.1957 81.1736 92.8917"
                            stroke="#307B26"
                            strokeWidth="3.15541"
                            strokeMiterlimit="10"
                        />
                        <animated.line
                            style={bodySpring}
                            x1="147.296"
                            y1="19.0128"
                            x2="153.583"
                            y2="13.1447"
                            stroke="#307B26"
                            strokeWidth="2.19873"
                            strokeLinecap="round"
                        />
                        <animated.line
                            style={bodySpring}
                            x1="1.09936"
                            y1="-1.09936"
                            x2="9.69973"
                            y2="-1.09936"
                            transform="matrix(0.731055 0.682318 0.682318 -0.731055 147.242 80.5667)"
                            stroke="#307B26"
                            strokeWidth="2.19873"
                            strokeLinecap="round"
                        />
                        <animated.line
                            style={bodySpring}
                            x1="152.026"
                            y1="50.52"
                            x2="160.626"
                            y2="50.52"
                            stroke="#307B26"
                            strokeWidth="2.19873"
                            strokeLinecap="round"
                        />
                        <animated.line
                            style={bodySpring}
                            x1="1.09936"
                            y1="-1.09936"
                            x2="9.69973"
                            y2="-1.09936"
                            transform="matrix(-0.731055 -0.682318 -0.682318 0.731055 14.4832 20.5667)"
                            stroke="#307B26"
                            strokeWidth="2.19873"
                            strokeLinecap="round"
                        />
                        <animated.line
                            style={bodySpring}
                            x1="14.4296"
                            y1="82.1205"
                            x2="8.14223"
                            y2="87.9887"
                            stroke="#307B26"
                            strokeWidth="2.19873"
                            strokeLinecap="round"
                        />
                        <animated.line
                            style={bodySpring}
                            x1="1.09936"
                            y1="-1.09936"
                            x2="9.69973"
                            y2="-1.09936"
                            transform="matrix(-1 0 0 1 10.7988 51.6194)"
                            stroke="#307B26"
                            strokeWidth="2.19873"
                            strokeLinecap="round"
                        />
                    </animated.svg>
                </animated.div>
                <div className={classes.mobilecontent}>
                    <div className={classes.slider}>
                        <animated.div
                            className={classes.drag}
                            {...bind()}
                            style={Object.assign({}, sliderColor, {
                                transform: xy.interpolate((x, y) => `translateY(${y}px)`),
                            })}
                        >
                            {!isMobile && (
                                <animated.div className={classes.dragtext} style={textSpring}>
                                    {" "}
                                    My heart was pounding or racing{" "}
                                </animated.div>
                            )}
                            {isMobile && (
                                <animated.div className={classes.dragtext} style={textSpring}>
                                    {" "}
                                    My heart was{" "}
                                </animated.div>
                            )}
                            {isMobile && (
                                <animated.div className={classes.dragtext} style={textSpring}>
                                    {" "}
                                    pounding or racing{" "}
                                </animated.div>
                            )}
                        </animated.div>
                        <animated.div className={classes.line}>
                            <animated.div className={classes.upbullet} style={sliderColor}></animated.div>
                            <animated.hr className={classes.hr}></animated.hr>
                            <animated.div className={classes.downbullet} style={sliderColor}></animated.div>
                        </animated.div>
                    </div>
                    <div className={classes.answers}>
                        <Typography className={classes.answer}>5 times or more</Typography>
                        <Typography className={classes.answer}>3-4 times</Typography>
                        <Typography className={classes.answer}>1-2 times</Typography>
                        <Typography className={classes.answer}>maybe once</Typography>
                        <Typography className={classes.answer}>never happened</Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}
