import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { InputBase, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Actions from '../../redux/actions';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    text: {
        marginTop: isMobile ? undefined : "20px",
        height: "50px",
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: "600",
        fontSize: isMobile ? "22px" : "30px",
        lineHeight: isMobile ? "22px" : "108.1%",
        alignSelf: "center",
        textAlign: "center",
        color: "#567284",
        width: isMobile ? "96%" : undefined,
    },
    inputBase: {
        maxWidth: '320px',
        borderBottom: '1px solid',
        borderColor: 'grey',
        fontWeight: 'bold',
        fontColor: 'green',
        marginBottom: isMobile ? '10px' : '24px',
        color: 'green',
        margin: '0px auto',
    },
    inputBaseInput: {
        textAlign: 'center',
    },
    emailContainer: {
        marginTop: isMobile ? '200px' : '200px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttoncontainer: {
        height: '200px',
        display: 'flex',
        paddingLeft: '30%',
        marginTop: isMobile ? '20px' : undefined,
    },
    dismissbutton: {
        width: isMobile ? '130px' : '130px',
        height: '40px',
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        textTransform: 'none',
        lineHeight: '24px',
        letterSpacing: 'normal',
        textAlign: 'center',
        borderRadius: '3px',
        color: 'white',
        backgroundColor: '#307B26',
        '&:hover': {
            backgroundColor: '#338030'
        },
    },
}));

export default function AgeView(props) {
    const { onNextReady } = props;
    const classes = useStyles();
    useSelector((state) => state.main.user);
    const dispatch = useDispatch();

    const [userAge, setUserAge] = useState(undefined);

    const onUserChangeAge = e => {
        if(!isNaN(Number(e.target.value))) {
            setUserAge(Number(e.target.value));
        } else {
            setUserAge('');
        };
    };

    const onSubmit = async (e) => {
        if (e.key === 'Enter') {
            // await action.dispatch age
            await dispatch(Actions.setSessionObject({ [e.target.name]: e.target.value }));
            // moving to email view
            setTimeout(async () => {
                await onNextReady();
            }, 500);
        }
    };

    const onClick = async () => {
        // await action.dispatch age
        await dispatch(Actions.setSessionObject({ user_age: userAge }));
        // moving to email view
        setTimeout(async () => {
            await onNextReady();
        }, 500);
    };

    return (
        <div className={classes.container}>
            <div>
                <Typography className={classes.text} variant='h4' component='h1'>
                    Do you mind telling us your age? 
                </Typography>
            </div>
            <div className={classes.emailContainer}>
                <InputBase name='user_age' fullWidth classes={{input: classes.inputBaseInput}} value={userAge} className={classes.inputBase} 
                    type='text' placeholder='Age' onChange={onUserChangeAge}  onKeyDown={(e) => onSubmit(e)}/>
            </div>
            <div className={classes.buttoncontainer}>
                <Button className={classes.dismissbutton} onClick={onClick}>
                    {userAge ? 'Continue' : 'Mabye Later'}
                </Button>
            </div>
            <div className={classes.whiteblock}></div>
        </div>
    );
}