import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormFields from "./FormFields";
import { config, useSpring, animated } from "react-spring";
import { isMobile } from "react-device-detect";
import { useInView } from "react-intersection-observer";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    content: {
        marginBottom: "20px",
    },
    text: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "900",
        fontSize: isMobile ? "22px" : "30px",
        textAlign: "center",
        color: "#567284",
    },
    ptext: {
        marginTop: '30px',
        fontFamily: "Lato",
        fontSize: isMobile ? "16px" : "20px",
        textAlign: "center",
        color: "#567284",
    },
    divider: {
        width: "200px",
        height: "5px",
        margin: "0px auto",
        display: "block",
        backgroundColor: "#ffd700",
        zIndex: 1,
    },
    centeredBlock: {
        display: "block",
        margin: "auto",
    },
}));

export default function FormPage(props) {
    const classes = useStyles();
    const { onNextReady } = props;
    const [spring, setSpring] = useSpring(() => ({
        from: {
            transform: "translate3d(0, 150px, 0)",
            scale: 0.7,
        },
        to: {
            transform: "translate3d(0, 0, 0)",
            scale: 1,
        },
        config: config.stiff,
    }));
    const session = useSelector((state) => state.main.session);
    const [ref, inView, entry] = useInView({
        threshold: 0,
    });

    // useEffect(() => {
    //     const loadStuff = async () => {
    //         await setSpring({
    //             from: {
    //                 transform: 'translate3d(0, 150px, 0)', scale: 0.7
    //             },
    //             to: {
    //                 transform: 'translate3d(0, 0, 0)', scale: 1
    //             },
    //             config: config.wobbly
    //         });
    //     };
    //     console.log('inView:', inView);
    //     if (inView) loadStuff();
    // }, [inView]);

    return (
        <animated.div style={spring} className={classes.container} ref={ref}>
            <div className={classes.content}>
                <Typography className={classes.text} variant="h4" component="h1">
                    Hey There, {session && session.username}
                </Typography>
                <p className={classes.ptext}>Great to have you :)</p>
                <svg
                    className={classes.centeredBlock}
                    width="194"
                    height="10"
                    viewBox="0 0 194 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 9.29936C0 9.17197 0.095649 9.04459 0.286947 8.9172L12.817 0.76433C13.1039 0.509552 13.6778 0.509552 13.9647 0.76433L25.347 8.53503L36.9205 0.509547C37.1118 0.382158 37.3031 0.38216 37.4944 0.38216C37.6857 0.38216 37.877 0.509547 38.0683 0.509547L49.2592 8.53503L61.2153 1.40127C61.5023 1.1465 61.9805 1.27388 62.3631 1.40127L72.8845 8.40764L85.3189 0.127387C85.5102 -1.94569e-06 85.7015 0 85.8928 0C86.0841 0 86.371 0.127385 86.4667 0.254773L96.5098 8.40764L109.518 0.891716C109.805 0.764328 110.283 0.764328 110.666 0.891716L121.57 8.53503L133.622 0.891716C133.909 0.636939 134.482 0.636939 134.769 0.891716L145.386 8.53503L157.916 0.76433C158.203 0.509552 158.682 0.509552 158.969 0.76433L171.307 8.40764L181.829 0.76433C182.02 0.636941 182.211 0.636943 182.403 0.636943C182.594 0.636943 182.881 0.76433 182.976 0.76433L193.785 8.7898C194.072 9.04458 194.072 9.42675 193.785 9.55414C193.498 9.80892 192.924 9.80892 192.637 9.55414L182.403 1.91083L172.168 9.29936L172.072 9.42675C171.881 9.68152 171.499 9.80892 171.116 9.68153C171.02 9.68153 170.829 9.55414 170.733 9.42675L158.395 1.78343L145.96 9.42675C145.865 9.55414 145.673 9.55414 145.482 9.55414H145.291C145.004 9.55414 144.813 9.55414 144.621 9.42675L134.1 1.91083L122.239 9.42675C122.048 9.55414 121.857 9.55414 121.665 9.55414H121.283C121.092 9.55414 120.9 9.42675 120.805 9.42675L109.996 2.1656L96.8924 9.68153C96.5098 9.93631 96.0315 9.80892 95.7446 9.55414L85.7015 1.52866L73.4584 9.68153C73.2671 9.80892 72.9801 9.80892 72.6932 9.80892H72.4062C72.215 9.80892 72.1193 9.68153 72.0237 9.68153L61.5979 2.67516L49.6418 9.68153C49.3549 9.93631 48.781 9.80892 48.494 9.68153L37.3987 1.78343L25.8252 9.80892C25.5383 10.0637 24.9644 10.0637 24.6774 9.80892L13.2952 2.03822L1.43473 9.68153C1.14779 9.93631 0.573894 9.93631 0.286947 9.68153C0.095649 9.55414 0 9.42675 0 9.29936Z"
                        fill="#848F99"
                    />
                </svg>
            </div>
            <FormFields onNextReady={onNextReady} />
        </animated.div>
    );
}
