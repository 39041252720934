import api from "../api";
export const GET_QUESTIONNAIRE = "GET_QUESTIONNAIRE";
export const SAVE_QUESTIONNAIRE = "SAVE_QUESTIONNAIRE";
export const SET_SESSION_OBJECT = "SET_SESSION_OBJECT";
export const DELETE_SESSION_OBJECT = "DELETE_SESSION_OBJECT";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const SET_CURRENT_QUESTION_INDEX = "SET_CURRENT_QUESTION_INDEX";
export const SET_QUESTIONNAIRE_OBJECT = "SET_QUESTIONNAIRE_OBJECT";

export function getQuestionnaire() {
    return async (dispatch, getState) => {
        dispatch({ type: GET_QUESTIONNAIRE });
        try {
            const doc = await api.questionnaires.get();
            dispatch({ type: GET_QUESTIONNAIRE, success: true, questionnaire: doc });
        } catch (error) {
            dispatch({ type: GET_QUESTIONNAIRE, success: false, error });
        }
    };
}
export function saveQuestionnaire() {
    return async (dispatch, getState) => {
        const {
            main: { questionnaire },
        } = getState();
        dispatch({ type: SAVE_QUESTIONNAIRE });
        try {
            const session = JSON.parse(localStorage.getItem("session") || "{}");
            const answers = [];
            Object.keys(session).forEach((k) => {
                if (k.length === 24) {
                    // hack to identify the question id keys
                    answers.push({ question_id: k, answer: session[k] });
                }
            });
            const doc = await api.questionnaires.save(questionnaire.id, answers);
            dispatch({ type: SAVE_QUESTIONNAIRE, success: true, results: doc });
        } catch (error) {
            dispatch({ type: SAVE_QUESTIONNAIRE, success: false, error });
        }
    };
}

export function setQuestionnaireObject(questionnaire) {
    return async (dispatch, getState) => {
        dispatch({ type: SET_QUESTIONNAIRE_OBJECT });
        try {
            dispatch({ type: SET_QUESTIONNAIRE_OBJECT, success: true, questionnaire: questionnaire });
        } catch (error) {
            console.error(error);
            dispatch({ type: SET_QUESTIONNAIRE_OBJECT, success: false, error });
        }
    };
}

export function setSessionObject(session) {
    return async (dispatch, getState) => {
        dispatch({ type: SET_SESSION_OBJECT });
        try {
            dispatch({ type: SET_SESSION_OBJECT, success: true, session: session });
        } catch (error) {
            console.error(error);
            dispatch({ type: SET_SESSION_OBJECT, success: false, error });
        }
    };
}

export function deleteSessionObject() {
    return async (dispatch, getState) => {
        dispatch({ type: DELETE_SESSION_OBJECT });
        try {
            dispatch({ type: DELETE_SESSION_OBJECT, success: true, session: {} });
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_SESSION_OBJECT, success: false, error });
        }
    };
}

export function createUser(email, firstName, lastName, gender) {
    return async (dispatch) => {
        dispatch({ type: CREATE_USER });
        try {
            const user = await api.users.create(email, firstName, lastName, gender);
            dispatch({ type: CREATE_USER, user, success: true });
        } catch (error) {
            dispatch({ type: CREATE_USER, success: false, error });
            throw error;
        }
    };
}

export function updateUser(firstName, lastName, gender) {
    return async (dispatch) => {
        dispatch({ type: UPDATE_USER });
        try {
            const user = await api.users.update(firstName, lastName, gender);
            dispatch({ type: UPDATE_USER, user, success: true });
        } catch (error) {
            dispatch({ type: UPDATE_USER, success: false, error });
            throw error;
        }
    };
}

export function authenticateUser(email, password) {
    return async (dispatch, getState) => {
        dispatch({ type: AUTHENTICATE_USER });
        try {
            const user = await api.users.authenticate(email, password);
            dispatch({ type: AUTHENTICATE_USER, user, success: true });
        } catch (error) {
            dispatch({ type: AUTHENTICATE_USER, success: false, error });
            throw error;
        }
    };
}

export function setCurrentQuestionIndex(newIndex) {
    return async (dispatch, getState) => {
        dispatch({ type: SET_CURRENT_QUESTION_INDEX });
        try {
            dispatch({ type: SET_CURRENT_QUESTION_INDEX, index: newIndex, success: true });
        } catch (error) {
            dispatch({ type: SET_CURRENT_QUESTION_INDEX, success: false, error });
        }
    };
}
