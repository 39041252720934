import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import { config, useSprings, animated } from "react-spring";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "../../redux/actions";
import { isMobile } from "react-device-detect";
import { useInView } from "react-intersection-observer";

const blue1 = (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.1" cx="29.6429" cy="29.0714" r="28.9286" fill="#4D64B0" />
        <circle cx="29.6429" cy="29.0714" r="28.3382" stroke="#4D64B0" strokeWidth="1.18076" />
    </svg>
);
const blue2 = (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.3" cx="29.6429" cy="29.0715" r="28.9286" fill="#4D64B0" />
        <circle cx="29.6429" cy="29.0715" r="28.4956" stroke="#4D64B0" strokeWidth="0.865889" />
    </svg>
);
const blue3 = (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.9" cx="29.6429" cy="29.0715" r="28.9286" fill="url(#paint0_linear)" />
        <circle cx="29.6429" cy="29.0715" r="28.4956" stroke="url(#paint1_linear)" strokeWidth="0.865889" />
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="62.7041"
                y1="38.5175"
                x2="-11.0933"
                y2="39.6983"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4D64B0" />
                <stop offset="1" stopColor="#4D64B0" stopOpacity="0" />
            </linearGradient>
            <linearGradient
                id="paint1_linear"
                x1="62.7041"
                y1="38.5175"
                x2="-11.0933"
                y2="39.6983"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4D64B0" />
                <stop offset="1" stopColor="#4D64B0" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);
const blue4 = (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.7" cx="29.6429" cy="29.0714" r="28.9286" fill="#4D64B0" />
        <circle cx="29.6429" cy="29.0714" r="28.4956" stroke="#4D64B0" strokeWidth="0.865889" />
    </svg>
);
const blue5 = (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="29.6429" cy="29.0715" r="28.9286" fill="#4D64B0" />
        <circle cx="29.6429" cy="29.0715" r="28.4956" stroke="url(#paint2_linear)" strokeWidth="0.865889" />
        <defs>
            <linearGradient
                id="paint2_linear"
                x1="62.7041"
                y1="38.5175"
                x2="-11.0933"
                y2="39.6983"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4D64B0" />
                <stop offset="1" stopColor="#4D64B0" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);
const green1 = (
    <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.1" cx="29.6424" cy="29.0714" r="28.9286" fill="#307B26" />
        <circle cx="29.6424" cy="29.0714" r="28.3382" stroke="#307B26" strokeWidth="1.18076" />
    </svg>
);
const green2 = (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.3" cx="29.6424" cy="29.0715" r="28.9286" fill="#307B26" />
        <circle cx="29.6424" cy="29.0715" r="28.4956" stroke="#307B26" strokeWidth="0.865889" />
    </svg>
);
const green3 = (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.9" cx="29.6424" cy="29.0715" r="28.9286" fill="url(#paint3_linear)" />
        <circle cx="29.6424" cy="29.0715" r="28.4956" stroke="#307B26" strokeWidth="0.865889" />
        <defs>
            <linearGradient
                id="paint3_linear"
                x1="69.5"
                y1="39"
                x2="-11.0937"
                y2="39.6983"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#307B26" />
                <stop offset="1" stopColor="#307B26" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);
const green4 = (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.7" cx="29.6424" cy="29.0714" r="28.9286" fill="#307B26" />
        <circle cx="29.6424" cy="29.0714" r="28.4956" stroke="#307B26" strokeWidth="0.865889" />
    </svg>
);
const green5 = (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="29.6424" cy="29.0715" r="28.9286" fill="#307B26" />
        <circle cx="29.6424" cy="29.0715" r="28.4956" stroke="#307B26" strokeWidth="0.865889" />
    </svg>
);
const pink1 = (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.1" cx="29.6434" cy="29.0714" r="28.9286" fill="#C73E76" />
        <circle cx="29.6434" cy="29.0714" r="28.3382" stroke="#C73E76" strokeWidth="1.18076" />
    </svg>
);
const pink2 = (
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.3" cx="28.9286" cy="28.9286" r="28.9286" fill="#C73E76" />
        <circle cx="28.9286" cy="28.9286" r="28.4956" stroke="#C73E76" strokeWidth="0.865889" />
    </svg>
);
const pink3 = (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.9" cx="29.6434" cy="29.0715" r="28.9286" fill="url(#paint4_linear)" />
        <circle cx="29.6434" cy="29.0715" r="28.4956" stroke="#C73E76" strokeWidth="0.865889" />
        <defs>
            <linearGradient
                id="paint4_linear"
                x1="69.501"
                y1="39"
                x2="-11.0927"
                y2="39.6983"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#CC3575" />
                <stop offset="1" stopColor="#CC3575" stopOpacity="0.24" />
            </linearGradient>
        </defs>
    </svg>
);
const pink4 = (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.7" cx="29.6434" cy="29.0714" r="28.9286" fill="#C73E76" />
        <circle cx="29.6434" cy="29.0714" r="28.4956" stroke="#C73E76" strokeWidth="0.865889" />
    </svg>
);
const pink5 = (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="29.6434" cy="29.0715" r="28.9286" fill="#C73E76" />
        <circle cx="29.6434" cy="29.0715" r="28.4956" stroke="#C73E76" strokeWidth="0.865889" />
    </svg>
);
const pink6 = (
    <svg width="59" height="58" viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.5" cx="29.6434" cy="29.0715" r="28.9286" fill="#C73E76" />
        <circle cx="29.6434" cy="29.0715" r="28.4956" stroke="#C73E76" strokeWidth="0.865889" />
    </svg>
);
const bullets = {
    blue: [blue1, blue2, blue3, blue4, blue5],
    green: [green1, green2, green3, green4, green5],
    pink: [pink1, pink2, pink3, pink4, pink5],
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0px 21px",
        height: isMobile ? "70vh" : "80vh",
        justifyContent: "space-between",
    },
    title: {
        marginTop: isMobile ? undefined : "20px",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: isMobile ? "23px" : "30px",
        lineHeight: isMobile ? "23px" : "108.1%",
        color: "#567284",
        alignSelf: isMobile ? "flex-start" : undefined,
    },
    prefix: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: isMobile ? "23px" : "30px",
        lineHeight: isMobile ? "23px" : "108.1%",
        color: "#567284",
        alignSelf: isMobile ? "flex-start" : undefined,
        marginTop: isMobile ? undefined : "20px",
    },
    flexRow: {
        height: isMobile ? "66px" : "85px",
        width: isMobile ? "322px" : "414px",
        background: "#FBFBFB",
        boxShadow: "0px 5.14286px 5.14286px rgba(0, 0, 0, 0.04)",
        borderRadius: "6.4px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    group: {
        cursor: "pointer",
    },
    flexGrow: {
        flexGrow: 1,
    },
    bulletValue: {
        margin: "0px 12px",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "18.7px",
        lineHeight: "22px",
        letterSpacing: "0.58px",
        color: "#6E6E75",
        "&.selected": {
            fontWeight: "bold",
        },
    },
    bullet: {
        margin: "0px 25px",
        height: "64px",
        width: "64px",
        backgroundRepeat: "no-repeat",
    },
    selectedBullet: {
        border: "2px solid",
        boxSizing: "border-box",
        borderRadius: "6.4px",
    },
}));

const plusOrMinus = () => {
    return Math.random() < 0.5 ? -1 : 1;
};
export default function BubblesQuestionView(props) {
    const rootNode = useRef(null);
    const { question, onNextReady } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const answer = useSelector((state) => state.main.session[question.id]);

    const [springs, setSprings] = useSprings(5, (index) => ({
        from: { opacity: 0.2, transform: `translate3d(0,${100 * Math.random() * plusOrMinus()}px,0)` },
        to: { opacity: 1, transform: `translate3d(0, 0, 0)` },
        config: config.stiff,
    }));

    const [ref, inView] = useInView({
        threshold: 0,
    });

    const onBulletClick = async (idx) => {
        await dispatch(Actions.setSessionObject({ [question.id]: idx }));
        setTimeout(async () => {
            await onNextReady();
        }, 500);
    };
    useEffect(() => {
        const loadStuff = async () => {
            await setSprings((index) => {
                return {
                    reset: true,
                    config: config.slow,
                    from: { opacity: 0.2, transform: `translate3d(0,${100 * Math.random() * plusOrMinus()}px,0)` },
                };
            });
        };
        if (question !== undefined && inView) {
            loadStuff();
        }
    }, [question, setSprings, inView]);

    return (
        <div className={classes.root} ref={ref}>
            <Typography className={classes.prefix}>
                {question.props.prefix} {question.title}
            </Typography>
            {springs.map((spring, idx) => {
                const selected = answer === idx;
                return (
                    <animated.div
                        key={idx}
                        style={spring}
                        className={classNames(classes.group)}
                        onClick={() => onBulletClick(idx)}
                    >
                        <div
                            className={classNames(classes.flexRow, selected ? classes.selectedBullet : undefined)}
                            style={{ borderColor: selected ? question.props.color : "#6E6E75" }}
                        >
                            <div className={classNames(classes.bullet)}>
                                {question.props.flavour === "pink" &&
                                question.possible_answers.length - idx - 1 === 2 &&
                                question.id.slice(20) === "a866"
                                    ? pink6
                                    : bullets[question.props.flavour][question.possible_answers.length - idx - 1]}
                            </div>
                            <Typography
                                style={{ color: `${selected ? question.props.color : "#6E6E75"}` }}
                                className={classNames(classes.bulletValue, selected ? "selected" : undefined)}
                            >
                                {question.possible_answers[question.possible_answers.length - idx - 1]}
                            </Typography>
                        </div>
                    </animated.div>
                );
            })}
        </div>
    );
}
