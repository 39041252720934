import { withStyles } from '@material-ui/core/styles';
import {Button} from '@material-ui/core';

const StyledButton = withStyles(theme => ({
    root: {
        width: '130px',
        height: '50px',
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: '700',
        fontStyle: 'normal',
        fontStretch: 'normal',
        textTransform: 'none',
        lineHeight: '24px',
        letterSpacing: 'normal',
        textAlign: 'center',
        borderRadius: '3px',
        color: 'white',
        backgroundColor: '#307B26',
        '&:hover': {
            backgroundColor: '#338030'
      },
    },
}))(Button);

export default StyledButton;