import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import Logo from './Questionnaire/images/myairlogo.svg';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {isMobile} from 'react-device-detect';
// import {green} from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: 'Lato',
        '& > h2': {
            fontFamily: 'Lato',
            textAlign: 'center',
            fontSize: '28px',
            color: '#555555',
            fontWeight: '600'
        }
    },
    logo: {
        margin: '12px auto',        
        backgroundSize: 'cover',
        backgroundImage: `url(${Logo})`,
        height: isMobile? '10vh' : '7vh',
        width: isMobile? '32vw': '80px',
        backgroundRepeat: 'no-repeat',
    },
    actions: {
        margin: '24px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > button > span': {
            fontSize: isMobile? '13px': '15px'
        }
    },
    contentText:  {
        fontFamily: 'Lato',
        fontSize: '18px',
        fontWeight: 500
    }
}));

const ColorButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#307B26',
        borderColor: '#307B26',      
        color: 'white',
        fontFamily: [
            'Lato',
        ].join(','),
        '& > span':{
            fontSize: '12px !important'
        },
        '&.MuiButton-outlined': {
            color: '#307B26',
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: '#F5F5F5',
                borderColor: '#307B26',
            },
            '&:active': {
                boxShadow: 'none',
                borderColor: '#307B26',
            },
        },
        '&:hover': {
            backgroundColor: '#308611',
            borderColor: '#307B26',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#307B26',
            borderColor: '#307B26',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    },
  })(Button);

export default function ExistingSessionDialog(props) {
    const {open, onClose, onDelete} = props;
    const classes = useStyles();
    const session = localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')) : {};

    return <Dialog classes= {{root: classes.root}} open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <div className={classes.logo} />
        <DialogTitle classes={{root: classes.title}} id="form-dialog-title">Welcome back, {session && session.username || 'Someone'}!</DialogTitle>
        <DialogContent>
            <DialogContentText className={classes.contentText}>
            Would you like to resume your previous questionnaire or start a new one?
            </DialogContentText>
        </DialogContent>
        <DialogActions classes={{root:classes.actions}}>
            <ColorButton variant="contained" onClick={onClose} color="primary">
                Resume Previous
            </ColorButton>
            <ColorButton variant="outlined" onClick={onDelete} color="secondary">
                Start a new one
            </ColorButton>
        </DialogActions>
    </Dialog>;
}