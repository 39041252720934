import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import { config, useSprings, animated } from "react-spring";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "../../redux/actions";
import { isMobile } from "react-device-detect";
import { useInView } from "react-intersection-observer";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
        minHeight: "calc(64vh)",
    },
    title: {
        marginLeft: isMobile ? "7px" : undefined,
        marginTop: isMobile ? undefined : "20px",
        height: "50px",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: isMobile ? "22px" : "30px",
        lineHeight: isMobile ? "22px" : "108.1%",
        alignSelf: isMobile ? "start" : "center",
        textAlign: isMobile ? "left" : "center",
        color: "#567284",
        maxWidth: isMobile ? undefined : "80%",
        width: isMobile ? "96%" : undefined,
    },
    prefix: {
        marginLeft: isMobile ? "25px" : undefined,
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: isMobile ? "22px" : "30px",
        lineHeight: isMobile ? "22px" : "108.1%",
        color: "#567284",
        alignSelf: isMobile ? "start" : "center",
    },
    firstanswer1: {
        cursor: "pointer",
        height: isMobile ? "167px" : "260px",
        width: isMobile ? "167px" : "260px",
        backgroundColor: "#C73E76",
        boxShadow: "10px 10px 10px 5px rgba(0, 0, 0, 0.1);",
        borderRadius: "50%",
        justifyContent: "center",
        marginLeft: "50px",
        "&:hover": {
            boxShadow: "13px 13px 13px 13px rgba(0, 0, 0, 0.15);",
        },
        position: "absolute",
        left: window.innerWidth > 1600 ? "20%" : isMobile ? undefined : "13%",
        right: isMobile ? "10px" : "56.12%",
        top: isMobile ? "300px" : "17%",
        bottom: isMobile ? undefined : "44.42%",
    },
    seacondanswer1: {
        cursor: "pointer",
        height: isMobile ? "167px" : "260px",
        width: isMobile ? "167px" : "260px",
        backgroundColor: "#C73E76",
        boxShadow: "10px 10px 10px 5px rgba(0, 0, 0, 0.1);",
        borderRadius: "50%",
        justifyContent: "center",
        marginLeft: "50px",
        "&:hover": {
            boxShadow: "13px 13px 13px 13px rgba(0, 0, 0, 0.15);",
        },
        position: "absolute",
        left: window.innerWidth > 1600 ? "23%" : isMobile ? "-40px" : "17%",
        top: isMobile ? "60px" : "17%",
    },
    thirdanswer1: {
        cursor: "pointer",
        height: isMobile ? "167px" : "260px",
        width: isMobile ? "167px" : "260px",
        backgroundColor: "#C73E76",
        boxShadow: "10px 10px 10px 5px rgba(0, 0, 0, 0.1);",
        borderRadius: "50%",
        justifyContent: "center",
        marginLeft: "50px",
        "&:hover": {
            boxShadow: "13px 13px 13px 13px rgba(0, 0, 0, 0.15);",
        },
        position: "absolute",
        left: window.innerWidth > 1600 ? "30%" : isMobile ? undefined : "27%",
        right: isMobile ? "10px" : undefined,
        top: isMobile ? "92px" : "47%",
    },
    circleLabel1: {
        marginTop: isMobile ? "60px" : "92px",
        textAlign: "center",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: isMobile ? "40px" : "63.1279px",
        lineHeight: isMobile ? "13px" : "20px",
        letterSpacing: "0.530135px",
        color: "#FFFFFF",
    },
    circleSmallLabel1: {
        margin: "auto",
        marginTop: isMobile ? "72px" : "115px",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: isMobile ? "15px" : "23.4038px",
        lineHeight: isMobile ? "16px" : "24px",
        width: isMobile ? "127px" : "200px",
        textAlign: "center",
        letterSpacing: isMobile ? "0.66px" : "1.04019px",
        color: "#FFFFFF",
    },
    firstanswer2: {
        cursor: "pointer",
        height: isMobile ? "150px" : "232px",
        width: isMobile ? "150px" : "232px",
        backgroundColor: "#896DA0",
        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1);",
        borderRadius: "50%",
        justifyContent: "center",
        "&:hover": {
            boxShadow: "13px 13px 13px 13px rgba(0, 0, 0, 0.15);",
        },
        position: "absolute",
        left: window.innerWidth > 1600 ? "45%" : isMobile ? "15px" : "43%",
        top: isMobile ? "290px" : "15%",
    },
    seacondanswer2: {
        cursor: "pointer",
        height: isMobile ? "150px" : "232px",
        width: isMobile ? "150px" : "232px",
        backgroundColor: "#896DA0",
        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1);",
        borderRadius: "50%",
        justifyContent: "center",
        "&:hover": {
            boxShadow: "13px 13px 13px 13px rgba(0, 0, 0, 0.15);",
        },
        position: "absolute",
        left: window.innerWidth > 1600 ? "54%" : isMobile ? "15px" : "57%",
        top: isMobile ? "290px" : "48%",
    },
    thirdanswer2: {
        cursor: "pointer",
        height: isMobile ? "150px" : "232px",
        width: isMobile ? "150px" : "232px",
        backgroundColor: "#896DA0",
        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1);",
        borderRadius: "50%",
        justifyContent: "center",
        "&:hover": {
            boxShadow: "13px 13px 13px 13px rgba(0, 0, 0, 0.15);",
        },
        position: "absolute",
        left: window.innerWidth > 1600 ? "57%" : isMobile ? undefined : "59%",
        right: isMobile ? "15px" : undefined,
        top: isMobile ? "290px" : "46%",
    },
    circleLabel2: {
        marginTop: isMobile ? "62px" : "78px",
        textAlign: "center",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: isMobile ? "40px" : "63.1279px",
        lineHeight: isMobile ? "13px" : "20px",
        letterSpacing: isMobile ? "0.34px" : "0.530135px",
        color: "#FFFFFF",
    },
    circleSmallLabel2: {
        width: isMobile ? "100px" : "180px",
        margin: "auto",
        marginTop: isMobile ? "12px" : "100px",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: isMobile ? "15px" : "23.4038px",
        lineHeight: isMobile ? "22px" : "24px",
        textAlign: "center",
        letterSpacing: isMobile ? "0.6px" : "1.04019px",
        color: "#FFFFFF",
    },
    firstanswer3: {
        cursor: "pointer",
        height: isMobile ? "125px" : "170px",
        width: isMobile ? "125px" : "170px",
        backgroundColor: "#307B26",
        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1);",
        borderRadius: "50%",
        justifyContent: "center",
        "&:hover": {
            boxShadow: "13px 13px 13px 13px rgba(0, 0, 0, 0.15);",
        },
        position: "absolute",
        left: window.innerWidth > 1600 ? "35%" : isMobile ? "145px" : "33%",
        top: isMobile ? "155px" : "62%",
    },
    seacondanswer3: {
        height: isMobile ? "125px" : "170px",
        width: isMobile ? "125px" : "170px",
        backgroundColor: "#307B26",
        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1);",
        borderRadius: "50%",
        justifyContent: "center",
        "&:hover": {
            boxShadow: "13px 13px 13px 13px rgba(0, 0, 0, 0.15);",
        },
        position: "absolute",
        left: window.innerWidth > 1600 ? "40%" : isMobile ? undefined : "38%",
        right: isMobile ? "40px" : undefined,
        top: isMobile ? "175px" : "62%",
    },
    thirdanswer3: {
        cursor: "pointer",
        height: isMobile ? "125px" : "170px",
        width: isMobile ? "125px" : "170px",
        backgroundColor: "#307B26",
        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1);",
        borderRadius: "50%",
        justifyContent: "center",
        "&:hover": {
            boxShadow: "13px 13px 13px 13px rgba(0, 0, 0, 0.15);",
        },
        position: "absolute",
        left: window.innerWidth > 1600 ? "26%" : isMobile ? "35px" : "22%",
        top: isMobile ? "307px" : "15%",
    },
    circleLabel3: {
        marginTop: isMobile ? "32px" : "42px",
        textAlign: "center",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: isMobile ? "52px" : "66px",
        lineHeight: isMobile ? "16px" : "21px",
        letterSpacing: isMobile ? "0.44px" : "0.560135px",
        color: "#FFFFFF",
    },
    circleSmallLabel3: {
        margin: "auto",
        marginTop: isMobile ? "50px" : "70px",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: isMobile ? "16px" : "23.4038px",
        lineHeight: isMobile ? "19px" : "24px",
        textAlign: "center",
        letterSpacing: isMobile ? "0.44px" : "1.04019px",
        color: "#FFFFFF",
    },
    firstanswer4: {
        cursor: "pointer",
        height: isMobile ? "95px" : "150px",
        width: isMobile ? "95px" : "150px",
        backgroundColor: "#4D64B0",
        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1);",
        borderRadius: "50%",
        justifyContent: "center",
        "&:hover": {
            boxShadow: "13px 13px 13px 13px rgba(0, 0, 0, 0.15);",
        },
        position: "absolute",
        left: window.innerWidth > 1600 ? "55%" : isMobile ? "32px" : "59%",
        top: isMobile ? "92px" : "64%",
    },
    seacondanswer4: {
        height: isMobile ? "95px" : "150px",
        width: isMobile ? "95px" : "150px",
        backgroundColor: "#4D64B0",
        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1);",
        borderRadius: "50%",
        justifyContent: "center",
        "&:hover": {
            boxShadow: "13px 13px 13px 13px rgba(0, 0, 0, 0.15);",
        },
        position: "absolute",
        left: window.innerWidth > 1600 ? "45%" : isMobile ? undefined : "44%",
        right: isMobile ? "32px" : "31.36%",
        top: isMobile ? "330px" : "27%",
        bottom: isMobile ? undefined : "28.18%",
    },
    thirdanswer4: {
        cursor: "pointer",
        height: isMobile ? "95px" : "150px",
        width: isMobile ? "95px" : "150px",
        backgroundColor: "#4D64B0",
        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1);",
        borderRadius: "50%",
        justifyContent: "center",
        "&:hover": {
            boxShadow: "13px 13px 13px 13px rgba(0, 0, 0, 0.15);",
        },
        position: "absolute",
        left: isMobile ? "25px" : "47%",
        top: isMobile ? "95px" : "20%",
    },
    circleLabel4: {
        marginTop: "32px",
        textAlign: "center",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: isMobile ? "40px" : "60px",
        lineHeight: isMobile ? "13px" : "19px",
        letterSpacing: isMobile ? "0.4px" : "0.530135px",
        color: "#FFFFFF",
    },
    circleSmallLabel4: {
        margin: "auto",
        marginTop: isMobile ? "40px" : "60px",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: isMobile ? "15px" : "23px",
        lineHeight: isMobile ? "13px" : "23px",
        textAlign: "center",
        letterSpacing: isMobile ? "0;35px" : "0.61px",
        color: "#FFFFFF",
    },
    firstanswer5: {
        cursor: "pointer",
        height: isMobile ? "83px" : "120px",
        width: isMobile ? "83px" : "120px",
        backgroundColor: "#EABA00",
        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1);",
        borderRadius: "50%",
        justifyContent: "center",
        "&:hover": {
            boxShadow: "13px 13px 13px 13px rgba(0, 0, 0, 0.15);",
        },
        position: "absolute",
        left: window.innerWidth > 1600 ? "65%" : isMobile ? undefined : "67%",
        right: isMobile ? "25px" : undefined,
        top: isMobile ? "60px" : "33%",
    },
    seacondanswer5: {
        height: isMobile ? "83px" : "120px",
        width: isMobile ? "83px" : "120px",
        backgroundColor: "#EABA00",
        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1);",
        borderRadius: "50%",
        justifyContent: "center",
        "&:hover": {
            boxShadow: "13px 13px 13px 13px rgba(0, 0, 0, 0.15);",
        },
        position: "absolute",
        left: window.innerWidth > 1600 ? "61%" : isMobile ? undefined : "67%",
        right: isMobile ? "25px" : undefined,
        top: isMobile ? "60px" : "22%",
    },
    thirdanswer5: {
        cursor: "pointer",
        height: isMobile ? "83px" : "120px",
        width: isMobile ? "83px" : "120px",
        backgroundColor: "#EABA00",
        boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1);",
        borderRadius: "50%",
        justifyContent: "center",
        "&:hover": {
            boxShadow: "13px 13px 13px 13px rgba(0, 0, 0, 0.15);",
        },
        position: "absolute",
        left: window.innerWidth > 1600 ? "65%" : isMobile ? "85px" : "68%",
        top: isMobile ? "205px" : "18%",
    },
    circleLabel5: {
        marginTop: "32px",
        textAlign: "center",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: isMobile ? "32px" : "49px",
        lineHeight: isMobile ? "10px" : "15px",
        letterSpacing: isMobile ? "0.27px" : "0.41px",
        color: "#FFFFFF",
    },
    circleSmallLabel5: {
        margin: "auto",
        marginTop: isMobile ? "-5px" : "40px",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: isMobile ? "12px" : "18.5px",
        lineHeight: isMobile ? "12px" : "18px",
        textAlign: "center",
        letterSpacing: isMobile ? "0.33px" : "0.42px",
        color: "#FFFFFF",
    },
    tapText: {
        marginTop: "22px",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "22px",
        lineHeight: "26px",
        textAlign: "center",
    },
    flexGrow: {
        flexGrow: 1,
    },
    bubblescontainer: {
        width: isMobile ? `calc(${window.innerWidth}px - 2%)` : `calc(${window.innerWidth}px - 20%)`,
        height: isMobile ? "400px" : "450px",
        display: "flex",
    },
}));

export default function BubblesQuestionView(props) {
    const { question, onNextReady } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const answer = useSelector((state) => state.main.session[question.id]);
    const [loadingAnimation, setLoadingAnimation] = useState(false);
    const [ref, inView] = useInView({
        threshold: 0,
    });

    const calc = isMobile
        ? (x, y, size) => [-(y - size / 2) / 16 + size / 5, (x - size / 2) / 16 - size / 4, 1.1]
        : (x, y, size) => [-(y - size / 2) / 16 + size / 5, (x - size / 2) / 16 - size / 4, 1.1];

    const trans = (x, y, s) => `perspective(300px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
    const trans2 = (x, y, s) => `perspective(500px) rotateX(${x - 5}deg) rotateY(${y}deg) scale(${s})`;

    const scaleTrans = (s) => `scale(${s})`;

    const [hoverSprings, setHoverProps] = useSprings(5, (index) => ({
        xys: [0, 0, 1],
        config: { mass: 5, tension: 200, friction: 20 },
    }));
    const [hoverSpringsSmall, setHoverSmallProps] = useSprings(5, (index) => ({
        xys: [0, 0, 1],
        config: { mass: 3, tension: 250, friction: 70 },
    }));
    const [opacitySprings, setOpacitySprings] = useSprings(5, (index) => ({
        from: { opacity: 0 },
        opacity: 1,
        config: config.slow,
        delay: 750,
    }));

    const [scaleSprings, setScaleSprings] = useSprings(5, (index) => ({
        from: { scale: 0.1 },
        scale: 1,
        config: config.wobbly,
        delay: 1000 + index * 250,
    }));

    const onBubbleClick = async (idx) => {
        if (idx === 0) await dispatch(Actions.setSessionObject({ [question.id]: 4 }));
        else if (idx === 1) await dispatch(Actions.setSessionObject({ [question.id]: 3 }));
        else if (idx === 3) await dispatch(Actions.setSessionObject({ [question.id]: 1 }));
        else if (idx === 4) await dispatch(Actions.setSessionObject({ [question.id]: 0 }));
        else await dispatch(Actions.setSessionObject({ [question.id]: 2 }));
        await setOpacitySprings((index) => {
            return { reset: true, opacity: idx !== index ? 0.3 : 1 };
        });
        setTimeout(async () => {
            onNextReady();
        }, 500);
    };

    const onMouseMove = async (idx, x, y) => {
        await setHoverProps((index) => {
            return idx === index ? { xys: calc(x, y, isMobile ? 30 + index * 16 : 130 + index * 25) } : {};
        });
        await setHoverSmallProps((index) => {
            return idx === index ? { xys: calc(x, y, isMobile ? 30 + index * 16 : 130 + index * 25) } : {};
        });
    };

    const onMouseLeave = (idx) => {
        setHoverProps((index) => {
            return { xys: [0, 0, 1] };
        });
        setHoverSmallProps((index) => {
            return { xys: [0, 0, 1] };
        });
    };

    useEffect(() => {
        const loadStuff = async () => {
            await setLoadingAnimation(true);
            await setScaleSprings((index) => ({
                from: { scale: 0.1 },
                scale: 1,
                config: config.wobbly,
                delay: (4 - index) * 50,
                reset: true,
                onRest: async () => {
                    await setLoadingAnimation(false);
                },
            }));
            let oppositeAnswer;
            if (answer !== undefined) {
                if (answer === 0) oppositeAnswer = 4;
                else if (answer === 1) oppositeAnswer = 3;
                else if (answer === 3) oppositeAnswer = 1;
                else if (answer === 4) oppositeAnswer = 0;
                else oppositeAnswer = 2;
                await setOpacitySprings((index) => {
                    return { reset: true, opacity: oppositeAnswer !== index ? 0.3 : 1 };
                });
            }
        };
        if (inView) loadStuff();
    }, [question, inView, setOpacitySprings]);

    return (
        <div className={classes.root} ref={ref}>
            <Typography className={classes.title}>
                {" "}
                {question.props.prefix} {question.title}
            </Typography>
            {question.id[23] === "4" && (
                <div className={classes.bubblescontainer}>
                    {[0, 1, 2, 3, 4].map((idx) => {
                        return (
                            <animated.div
                                key={idx}
                                className={classes[`firstanswer${idx + 1}`]}
                                onClick={() => onBubbleClick(idx)}
                                onMouseMove={({ clientX: x, clientY: y }) => onMouseMove(idx, x, y)}
                                onMouseLeave={() => onMouseLeave(idx)}
                                style={Object.assign({}, opacitySprings[idx], {
                                    transform: loadingAnimation
                                        ? scaleSprings[idx].scale.interpolate(scaleTrans)
                                        : hoverSprings[idx].xys.interpolate(trans),
                                })}
                            >
                                <Typography className={classes[`circleLabel${idx + 1}`]}>{""}</Typography>
                                <Typography className={classes[`circleSmallLabel${idx + 1}`]}>
                                    {question.possible_answers[4 - idx]}
                                </Typography>
                            </animated.div>
                        );
                    })}
                </div>
            )}
            {question.id[23] === "6" && (
                <div className={classes.bubblescontainer}>
                    {[0, 1, 2, 3, 4].map((idx) => {
                        return (
                            <animated.div
                                key={idx}
                                className={classes[`thirdanswer${idx + 1}`]}
                                onClick={() => onBubbleClick(idx)}
                                onMouseMove={({ clientX: x, clientY: y }) => onMouseMove(idx, x, y)}
                                onMouseLeave={() => onMouseLeave(idx)}
                                style={Object.assign({}, opacitySprings[idx], {
                                    transform: loadingAnimation
                                        ? scaleSprings[idx].scale.interpolate(scaleTrans)
                                        : hoverSprings[idx].xys.interpolate(trans),
                                })}
                            >
                                <Typography className={classes[`circleLabel${idx + 1}`]}>{""}</Typography>
                                <Typography className={classes[`circleSmallLabel${idx + 1}`]}>
                                    {question.possible_answers[4 - idx]}
                                </Typography>
                            </animated.div>
                        );
                    })}
                </div>
            )}
            {question.id[23] !== "4" && question.id[23] !== "6" && (
                <div className={classes.bubblescontainer}>
                    {[0, 1, 2, 3, 4].map((idx) => {
                        return (
                            <animated.div
                                key={idx}
                                className={classes[`seacondanswer${idx + 1}`]}
                                onClick={() => onBubbleClick(idx)}
                                onMouseMove={({ clientX: x, clientY: y }) => onMouseMove(idx, x, y)}
                                onMouseLeave={() => onMouseLeave(idx)}
                                style={Object.assign({}, opacitySprings[idx], {
                                    transform: loadingAnimation
                                        ? scaleSprings[idx].scale.interpolate(scaleTrans)
                                        : hoverSpringsSmall[idx].xys.interpolate(trans2),
                                })}
                            >
                                <Typography className={classes[`circleLabel${idx + 1}`]}>{""}</Typography>
                                <Typography className={classes[`circleSmallLabel${idx + 1}`]}>
                                    {question.possible_answers[4 - idx]}
                                </Typography>
                            </animated.div>
                        );
                    })}
                </div>
            )}
            <div className={classes.flexGrow} />
            <Typography className={classes.tapText} style={{ color: `${question.props.color}` }}>
                Tap on your answer
            </Typography>
        </div>
    );
}
