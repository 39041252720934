// const BASE_URL = 'http://localhost:3001';
// const SERVER_URL = 'http://localhost:3000';

const BASE_URL = "https://api.myair.ai";
// const SERVER_URL = 'https://s3.eu-central-1.amazonaws.com/www.myair.ai';

//STAGING
const SERVER_URL = "http://staging.myair.ai.s3-website.eu-central-1.amazonaws.com";

//DeMo
// const SERVER_URL = 'https://s3.eu-central-1.amazonaws.com/demo-startx.myair.ai';

async function postApi(path, body) {
    const strBody = typeof body === "string" ? body : JSON.stringify(body);
    console.log("posting body " + strBody.length + " bytes long", strBody);
    const data = await fetch(`${BASE_URL}${path}`, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("access_token")
                ? "BEARER " + localStorage.getItem("access_token")
                : undefined,
        },
        method: "POST",
        mode: "cors",
        body: strBody,
    });

    const response = await data.json();
    if (data.status === 200) {
        return response.result;
    } else {
        const error = new Error(data.statusText);
        error.code = data.status;
        throw error;
    }
}

async function patchApi(path, body) {
    const strBody = typeof body === "string" ? body : JSON.stringify(body);
    console.log("patching body " + strBody.length + " bytes long", strBody);
    const data = await fetch(`${BASE_URL}${path}`, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("access_token")
                ? "BEARER " + localStorage.getItem("access_token")
                : undefined,
        },
        method: "PATCH",
        mode: "cors",
        body: strBody,
    });

    const response = await data.json();
    if (data.status === 200) {
        return response.result;
    } else {
        const error = new Error(data.statusText);
        error.code = data.status;
        throw error;
    }
}

async function getApi(path, query) {
    const queryPart = query !== undefined ? `?${query}` : "";
    const data = await fetch(`${BASE_URL}${path}${queryPart}`, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("access_token")
                ? "BEARER " + localStorage.getItem("access_token")
                : undefined,
        },
        mode: "cors",
        method: "GET",
    });
    const response = await data.json();
    if (data.status === 200) {
        return response.result;
    } else {
        const error = new Error(data.statusText);
        error.code = data.status;
        throw error;
    }
}

async function deleteApi(path, query) {
    const queryPart = query !== undefined ? `?${query}` : "";
    const data = await fetch(`${BASE_URL}${path}${queryPart}`, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("access_token")
                ? "BEARER " + localStorage.getItem("access_token")
                : undefined,
        },
        mode: "cors",
        method: "DELETE",
    });
    const response = await data.json();
    console.log("got after get:", response);
    if (data.status === 200) {
        return response.result;
    } else {
        const error = new Error(data.statusText);
        error.code = data.status;
        throw error;
    }
}

export class Questionnaires {
    async get() {
        return getApi(`/questionnaires`);
    }
    async save(questionnaireId, answers) {
        return postApi(`/questionnaires/${questionnaireId}/answers`, answers);
    }
    async getResults(questionnaireId, userId) {
        return getApi(`/questionnaires/${questionnaireId}/results?user_id=${userId}`);
    }
}
export class Users {
    async authenticate(emailOrToken, password) {
        const body = password ? { email: emailOrToken, password } : { token: emailOrToken };
        const user = await postApi("/users/authenticate", body);
        localStorage.setItem("current_user", JSON.stringify(user));
        localStorage.setItem("access_token", user.access_token);

        const session = localStorage.getItem("session") ? JSON.parse(localStorage.getItem("session")) : {};
        session.email = user.email;
        localStorage.setItem("session", JSON.stringify(session));

        return user;
    }
    async getUserDailySummary(userId, day, month, year, factor) {
        return getApi(
            `/users/${userId}/activities/dailySummary?factor=${factor}&year=${year}&month=${month}&day=${day}`
        );
    }

    async getUserSleepSummary(userId, day, month, year, factor) {
        return getApi(
            `/users/${userId}/activities/sleepSummary?factor=${factor}&year=${year}&month=${month}&day=${day}`
        );
    }
    async getUserRangedSummaries(userId, startDate, endDate) {
        return getApi(`/users/${userId}/activities/dailySummaries?start_date=${startDate}&end_date=${endDate}`);
    }
    async getUserRangedSleep(userId, startDate, endDate) {
        return getApi(`/users/${userId}/activities/sleepSummaries?start_date=${startDate}&end_date=${endDate}`);
    }
    async getUserDailySummaries(userId) {
        return getApi(`/users/${userId}/activities/dailySummaries`);
    }

    async getUserSleepSummaries(userId) {
        return getApi(`/users/${userId}/activities/sleepSummaries`);
    }

    async create(email, firstName, lastName, gender) {
        const body = {
            username: email,
            email,
            gender,
            first_name: firstName,
            last_name: lastName,
        };
        const user = await postApi("/users", body);
        localStorage.setItem("current_user", JSON.stringify(user));
        localStorage.setItem("access_token", user.access_token);
        return user;
    }

    async update(firstName, lastName, gender) {
        const body = {
            first_name: firstName,
            last_name: lastName,
            gender,
        };
        const user = await patchApi("/users", body);
        return user;
    }

    async subscribe(email) {
        return getApi(`/users/subscribe?email=${encodeURIComponent(email)}`);
    }

    async facebookUser(body) {
        const user = await postApi("/users/facebook", body);
        localStorage.setItem("current_user", JSON.stringify(user));
        localStorage.setItem("access_token", user.access_token);
        return user;
    }
}

const def = {
    users: new Users(),
    questionnaires: new Questionnaires(),
};
export { def as default, BASE_URL, SERVER_URL };
