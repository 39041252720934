import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { InputBase, Typography, Button } from '@material-ui/core';
import { makeStyles, createGenerateClassName } from '@material-ui/core/styles';
import * as Actions from '../../redux/actions';
import { isMobile } from 'react-device-detect';
import { BASE_URL } from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { Redirect } from 'react-router-dom';
import { useInView } from "react-intersection-observer";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    content: {
        marginBottom: '20px',
    },
    text: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: isMobile ? '22px' : '30px',
        textAlign: 'center',
        color: '#6f7478',
    },
    inputBase: {
        width: '250px',
        borderBottom: '1px solid',
        borderColor: 'grey',
        fontWeight: 'bold',
        fontColor: 'green',
        marginBottom: isMobile ? '10px' : '24px',
        color: 'green',
        marginTop: isMobile ? '-10px' : undefined,
    },
    inputBaseInput: {
        textAlign: 'center',
    },
    ptext: {
        fontFamily: 'Lato',
        fontSize: isMobile ? '16px' : '20px',
        textAlign: 'center',
        color: '#6f7478',
        marginTop: isMobile ? '15px' : undefined,
    },
    logintext: {
        fontFamily: 'Lato',
        fontSize: isMobile ? '14px' : '16px',
        textAlign: 'center',
        color: '#6f7478',
        marginTop: isMobile ? '50px' : '40px',
    },
    googleButton: {
        fontFamily: 'Lato',
        borderRadius: '0px',
        textTransform: 'initial',
        height: '40px',
        width: isMobile ? '80%' : '250px',
        marginTop: '10px',
        backgroundColor: '#dd4b39',
        color: '#ffffff',
    },
    facebookButton: {
        fontFamily: 'Lato',
        textTransform: 'initial',
        borderRadius: '0px',
        height: '40px',
        width: isMobile ? '80%' : '250px',
        marginTop: '10px',
        backgroundColor: '#4267B2',
        color: '#ffffff',
    },
    submit: {
        fontFamily: 'Lato',
        textTransform: 'initial',
        borderRadius: '0px',
        height: '40px',
        width: isMobile ? '80%' : '250px',
        margin: isMobile ? '15px auto' : '10px 0px',
        backgroundColor: 'green',
        color: '#ffffff',
    },
    logincontainer: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: isMobile ? 'flex-start' : 'center',
        marginTop: isMobile ? '-50px' : undefined,
    },
    bottomtext: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '80%',
        margin: 'auto',
    },
    smalltext: {
        fontFamily: 'Lato',
        fontSize: '14px',
        textAlign: 'center',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    toggletext: {
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 800,
        textAlign: 'center',
        color: 'green',
        cursor: 'pointer',
    },
    left: {
        display: 'flex',
        flexDirection: 'column',
        width: isMobile ? '100%' : '300px',
        alignItems: 'center',
    },
    right: {
        display: 'flex',
        flexDirection: 'column',
        width: isMobile ? '100%' : '300px',
        alignItems: 'center',
        marginTop: isMobile ? '-35px' : undefined,
    },
}));

export default function EmailView(props) {
    const { showNextButton } = props;
    const classes = useStyles();
    const session = localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')) : {};
    const email = session.email;
    useSelector((state) => state.main.user);
    const dispatch = useDispatch();
    const [name, setName] = useState(localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')) : {});
    const [userEmail, setUserEmail] = useState(false);
    const [userPassword, setUserPassword] = useState(false);
    const [redirect, setRedirect] = useState(undefined);
    const [textToggle, setTextToggle] = useState(false);
    const [ref, inView] = useInView({
        threshold: 0,
    });

    const onForgotClick = () => {
        alert('not implemented yet');
    };

    const signClick = () => {
        setTextToggle(!textToggle);
    };

    const onEmailChange = async (e) => {
        setUserEmail(e.target.value);
        await dispatch(Actions.setSessionObject({ email: e.target.value }));
    };

    const onPasswordChange = async (e) => {
        setUserPassword(e.target.value);
        await dispatch(Actions.setSessionObject({ password: e.target.value }));
    };

    const onMouseLeave = () => {
        showNextButton(true);
    };

    const onMouseEnter = () => {
        showNextButton(false);
    };

    const onSubmit = async (e) => {
        if (e.key === 'Enter' || e === 'Button') {
            let err = false;
            if (!userEmail  || !userPassword) {
                alert('Email or Password are missing');
                return;
            } else if (textToggle) {
                try {
                    await dispatch(Actions.authenticateUser(userEmail, userPassword));
                } catch (e) {
                    err = true;
                    alert('error in authentication');
                    return;
                }
            } else {
                try {
                    await dispatch(Actions.createUser(userEmail, userPassword, session.first_name, session.last_name, session.gender));
                    await dispatch(Actions.updateUser(session.first_name, session.last_name, session.gender));
                } catch (e) {
                    err = true;
                    alert('error when creating user', e);
                    return;
                }
            }
            if (err) return;
            await setRedirect('/calc');
        }
    };

    useEffect(() => {
        const getName = async () => {
            setName(localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')) : {});
        }
        if(inView) getName();
    }, [inView]);

    return redirect ? (
        <Redirect to={redirect} />
    ) : (
        <div className={classes.container} ref={ref}>
            <div className={classes.content}>
                <Typography className={classes.text} variant='h4' component='h1'>
                    {/* {session && session.first_name}, you got this! */}
                    {name.first_name}, you got this!
                </Typography>
                <p className={classes.ptext}>Your stressless mix and routine plan are ready!</p>
            </div>
            <div className={classes.logincontainer}>
                <div className={classes.left}>
                    <p className={classes.logintext}>Log-in with socials networks</p>
                    <Button className={classes.facebookButton} href={BASE_URL + '/facebook/authenticate'}>
                        <FontAwesomeIcon
                            icon={faFacebookF}
                            size='lg'
                            style={{ position: 'absolute', left: isMobile ? 8 : 20, color: '#ffffff', marginRight: '10px' }}
                        />
                        <p style={{ fontFamily: 'Lato', marginLeft: '18px' }}>Continue with Facebook</p>
                    </Button>
                    <div style={{ height: '10px' }} />
                    <Button className={classes.googleButton} href={BASE_URL + '/google/authenticate'}>
                        <FontAwesomeIcon
                            icon={faGoogle}
                            size='lg'
                            style={{ position: 'absolute', left: isMobile ? 5 : 18, color: '#ffffff' }}
                        />
                        <p style={{ fontFamily: 'Lato' }}>Continue with Google</p>
                    </Button>
                </div>
                {!isMobile && <div style={{ height: '300px', width: '10px', borderLeft: '1px solid green', margin: '0px 40px 0px 50px'}}></div>}
                {isMobile && <div style={{ height: '2px', width: '350px', borderTop: '2px solid green', marginTop: '25px'}}></div>}
                <div className={classes.right}>
                    <p className={classes.logintext}>{textToggle ? 'Sign-in': 'Sign-up'} to receive your full analysis</p>
                    <div>
                        <InputBase name='email' fullWidth classes={{ input: classes.inputBaseInput }} className={classes.inputBase}
                            type='text' defaultValue={email} onKeyDown={(e) => onSubmit(e)} onChange={onEmailChange} onMouseLeave={onMouseLeave}
                            onMouseEnter={onMouseEnter} placeholder='Email' />
                    </div>
                    <div style={{marginTop: isMobile ? '10px' : undefined}}>
                        <InputBase name='password' fullWidth classes={{ input: classes.inputBaseInput }} className={classes.inputBase}
                            type='password' onKeyDown={(e) => onSubmit(e)} onChange={onPasswordChange} onMouseLeave={onMouseLeave}
                            onMouseEnter={onMouseEnter} placeholder='Password' />
                    </div>
                    <Button className={classes.submit} onClick={(e) => onSubmit('Button')}>
                        {textToggle ? 'Sign-in': 'Sign-up'}
                    </Button>
                    <div className={classes.bottomtext}>
                        <p className={classes.toggletext} onClick={signClick}> {textToggle ? 'Sign-up': 'Sign-in'} </p>
                        <p className={classes.smalltext} onClick={onForgotClick}> forgot password ? </p>
                    </div>
                </div>
            </div>
        </div>
    );
}