import { Route, BrowserRouter } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import App from "./App";
import theme from "./Theme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import QuestionnaireView from "./components/Questionnaire/Main/QuestionnaireView";
import { LinearProgress } from "@material-ui/core";

const QuestionnaireCallback = lazy(() => import("./components/Questionnaire/QuestionnaireCallback"));
const QuestionnaireSummary = lazy(() => import("./components/Questionnaire/QuestionnaireSummary"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const CalculatingView = lazy(() => import("./components/Questionnaire/CalculatingView"));
const Results = lazy(() => import("./components/Questionnaire/Results"));

// import './index.css';

// const PrivateRoute = ({ component: Component, ...rest }) => (
//     <LoggedInUserContext.Consumer>
//         {context => (
//             <Route {...rest} render={props =>
//                 context.user_id ? (
//                     <Component {...props} />
//                 ) : (<LoginView {...props} />) }
//         />)}
//     </LoggedInUserContext.Consumer>
// );

const MyAirRouter = () => {
    return (
        <BrowserRouter basename="/">
            <Suspense fallback={<LinearProgress />}>
                <MuiThemeProvider theme={theme}>
                    <App>
                        {/* <Route exact path="/login" component={LoginView} /> */}
                        {/* <Route exact path="/" component={LandingSite} /> */}
                        <Route exact path="/" component={QuestionnaireView} />
                        <Route exact path="/calc/" component={CalculatingView} />
                        <Route exact path="/results/" component={QuestionnaireSummary} />
                        <Route exact path="/callback" component={QuestionnaireCallback} />
                        <Route exact path="/dashboard/" component={Dashboard} />
                        <Route exact path="/test-results" component={Results} />
                        {/* <Route exact path="/formPage" component={FormPage} /> */}
                        {/* <Route exact path="/garmin" component={Garmin} /> */}
                    </App>
                </MuiThemeProvider>
            </Suspense>
        </BrowserRouter>
    );
};

export default MyAirRouter;
