import * as Actions from "../actions";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loadingPhase: "",
  questionnaire: undefined,
  session: {},
  currentQuestionIndex: 0,
};

const getQuestionnaire = (state, action) => {
  if (typeof action.success === "boolean") {
    state.questionnaire = action.questionnaire;
    state.loadingPhase = "";
  } else {
    state.loadingPhase = "fetching";
  }
};

const saveQuestionnaire = (state, action) => {
  if (typeof action.success === "boolean") {
    state.results = action.results;
    state.loadingPhase = "";
  } else {
    state.loadingPhase = "fetching";
  }
};

const setQuestionnaireObject = (state, action) => {
  if (typeof action.success === "boolean") {
    state.questionnaire = action.questionnaire;
    state.loadingPhase = "";
  } else {
    state.loadingPhase = "fetching";
  }
};

const setSessionObject = (state, action) => {
  if (typeof action.success === "boolean") {
    state.session = Object.assign(state.session, action.session);
    localStorage.setItem("session", JSON.stringify(state.session));
    state.loadingPhase = "";
  } else {
    state.loadingPhase = "fetching";
  }
};

const deleteSessionObject = (state, action) => {
  if (typeof action.success === "boolean") {
    state.session = {};
    localStorage.setItem("session", JSON.stringify(state.session));
    state.loadingPhase = "";
  } else {
    state.loadingPhase = "fetching";
  }
};

const createUser = (state, action) => {
  if (typeof action.success === "boolean") {
    state.user = action.user;
  }
};

const updateUser = (state, action) => {
  if (typeof action.success === "boolean") {
    state.user = action.user;
  }
};

const authenticateUser = (state, action) => {
  if (typeof action.success === "boolean") {
    state.user = action.user;
  }
};

const setCurrentQuestionIndex = (state, action) => {
  if (typeof action.success === "boolean") {
    state.currentQuestionIndex = action.index;
  }
};

const handlers = {};
handlers[Actions.GET_QUESTIONNAIRE] = getQuestionnaire;
handlers[Actions.SAVE_QUESTIONNAIRE] = saveQuestionnaire;
handlers[Actions.SET_SESSION_OBJECT] = setSessionObject;
// handlers[Actions.SET_USER_EMAIL] = setUserEmail;
// handlers[Actions.SET_USER_PASSWORD] = setUserPassword;
handlers[Actions.CREATE_USER] = createUser;
handlers[Actions.UPDATE_USER] = updateUser;
handlers[Actions.AUTHENTICATE_USER] = authenticateUser;

handlers[Actions.SET_CURRENT_QUESTION_INDEX] = setCurrentQuestionIndex;
handlers[Actions.SET_QUESTIONNAIRE_OBJECT] = setQuestionnaireObject;
handlers[Actions.DELETE_SESSION_OBJECT] = deleteSessionObject;

export default createReducer(initialState, handlers);
