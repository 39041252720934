import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import { config, useSpring, animated } from "react-spring";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "../../redux/actions";
import { isMobile } from "react-device-detect";
import { useGesture } from "react-with-gesture";
import { add } from "vec-la";
import { useInView } from "react-intersection-observer";
import arrow from "./images/arrow.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
        height: isMobile ? "520px" : "700px",
    },
    title: {
        marginLeft: isMobile ? "25px" : undefined,
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: isMobile ? "22px" : "30px",
        lineHeight: "108.1%",
        color: "#567284",
        alignSelf: isMobile ? "flex-start" : "center",
        maxWidth: isMobile ? "320px" : undefined,
    },
    prefix: {
        marginTop: isMobile ? undefined : "20px",
        marginLeft: isMobile ? "25px" : undefined,
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: isMobile ? "22px" : "30px",
        lineHeight: "108.1%",
        color: "#567284",
        alignSelf: isMobile ? "flex-start" : "center",
        maxWidth: isMobile ? "320px" : undefined,
    },
    flexGrow: {
        flexGrow: 1,
    },
    speakers: {
        marginTop: isMobile ? "22px" : "-10px",
    },
    answer: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "30px",
        lineHeight: "22px",
        textAlign: "center",
        letterSpacing: "0.6px",
        marginTop: "24px",
        alignSelf: "center",
    },
    slideText: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "28px",
        textAlign: "center",
        margin: isMobile ? "auto" : "0px 0px",
    },
    stepper: {
        display: "flex",
        flexDirection: "row",
        minWidth: isMobile ? "120px" : "260px",
        alignItems: "center",
        marginTop: isMobile ? "52px" : "0px",
    },
    steppertext: {
        display: "flex",
        flexDirection: "row",
        height: "40px",
        width: isMobile ? "300px" : "670px",
        minWidth: isMobile ? "120px" : "260px",
        marginTop: isMobile ? "-10px" : undefined,
    },
    helptext: {
        width: isMobile ? "50px" : "150px",
        fontFamily: "Lato",
        fontWeight: "bold",
        fontSize: isMobile ? "12px" : "16px",
        lineHeight: isMobile ? "13px" : "22px",
        textAlign: "center",
        letterSspacing: isMobile ? "0.319649px" : "0.607132px",
    },
    oneStep: {
        display: "flex",
        flexDirection: "column",
        minHeight: "82px",
        minWidth: isMobile ? "64px" : "128px",
        alignItems: "center",
        alignSelf: "auto",
        flex: "0 1 auto",
        justifyContent: "center",
    },
    circle: {
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        backgroundColor: "#C4C4C4",
    },
    stepLabel: {
        width: "120px",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "normal !important",
        fontSize: "16px",
        lineHeight: "22px",
        textAlign: "center",
        letterSpacing: "0.61px",
    },
    bullet: {
        margin: isMobile ? "0px 12px" : "0px 25px",
        height: "64px",
        width: "64px",
        backgroundRepeat: "no-repeat",
        zIndex: "20",
        textAlign: "center",
        "&::after": {
            display: "inline-block",
            height: "64px",
            width: "64px",
            content: "''",
            background: `url(${arrow}) no-repeat 28px 20px`,
            left: "28px",
        },
    },
    hr: {
        position: "absolute",
        width: isMobile ? "260px" : "510px",
        marginLeft: isMobile ? "32px" : "64px",
        marginTop: "1px",
        zIndex: "-99",
        opacity: isMobile ? 0.7 : 1,
    },
    answerbullete: {
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: isMobile ? "30px" : "40px",
        height: isMobile ? "30px" : "40px",
        borderRadius: "50%",
        backgroundColor: "#C73E76",
        cursor: "-webkit-grab",
        whiteSpace: "pre",
        boxShadow: "1px 1px 14px rgba(0, 0, 0, 0.05)",
        zIndex: 1,
    },
    animationbullete: {
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: isMobile ? "30px" : "40px",
        height: isMobile ? "30px" : "40px",
        borderRadius: "50%",
        backgroundColor: "#c4c4c4",
        cursor: "-webkit-grab",
        whiteSpace: "pre",
        boxShadow: "1px 1px 14px rgba(0, 0, 0, 0.05)",
        zIndex: 1,
        left: isMobile ? "calc(50% - 110px)" : "calc(50% - 220px)",
    },
}));

const colors = [
    { fill: "#FCF5DE", stroke: "#EABA00", mouthD: 0 },
    { fill: "#F4F7FF", stroke: "#4D64B0", mouthD: 1 },
    { fill: "#DCEDDC", stroke: "#307B26", mouthD: 2 },
    { fill: "#E6DDEB", stroke: "#896DA0", mouthD: 3 },
    { fill: "#FFF0F6", stroke: "#C73E76", mouthD: 4 },
];
const prefix = ["never happened", "maybe once", "1-2 times", "3-4 times", "5 times or more", "- -"];

export default function ImageManQuestionView(props) {
    const { question, onNextReady, isVisible, changeInnerDrag } = props;
    const [ref, inView] = useInView({
        threshold: 0,
    });
    const classes = useStyles();
    const answer = useSelector((state) => state.main.session[question.id]);
    const dispatch = useDispatch();
    const currentVal = (answer !== undefined && question.possible_answers[answer]) || "- -";
    const currentStroke = (answer !== undefined && colors[answer].stroke) || "#C3C3C3";
    const currentFill = (answer !== undefined && colors[answer].fill) || "#FFFFFF";
    const [text, setText] = useState(prefix[5]);
    const [textSpring, setTextSpring] = useSpring(() => ({ color: "#C3C3C3", config: config.slow }));
    const [nextDeg, setNextDeg] = useState(360);
    const spiralSpring = useSpring({
        reset: true,
        from: { transform: `rotateZ(${0}deg)`, transformOrigin: "46.4% 45.7%", opacity: 1 },
        to: { transform: `rotateZ(${nextDeg}deg)`, transformOrigin: "46.4% 45.7%", opacity: 0.3 },
        config: { duration: 2000 },
    });
    const [bulletAnimationSpring, setBulletAnimationSpring] = useSpring(() => ({
        reset: true,
        to: [
            { opacity: 1, transform: "scale(2.5, 2.5)" },
            { opacity: 1, transform: "scale(2.5, 2.5)" },
            { opacity: 0, transform: "scale(0, 0)" },
        ],
        from: { opacity: 1, transform: "scale(0, 0)" },
        config: config.wobbly,
    }));
    const [smileyAnimationSpring, setSmileyAnimationSpring] = useSpring(() => ({
        reset: true,
        from: { opacity: 1, transform: "scale(0.5, 0.5)" },
        to: { opacity: 1, transform: "scale(1, 1)" },
        config: config.wobbly,
        delay: 200,
    }));
    const [bodySpring, setBodySpring] = useSpring(() => ({
        stroke: currentStroke,
        fill: "#ffffff",
        config: config.slow,
    }));
    const [spiralColorSpring, setSpiralColorSpring] = useSpring(() => ({ fill: currentStroke, config: config.slow }));
    const [colorSpring, setColorSpring] = useSpring(() => ({
        borderTop: `1.05px solid #C3C3C3`,
        backgroundColor: "#C3C3C3",
        config: config.slow,
    }));
    const [bulleteSpring, setBulleteSpring] = useSpring(() => ({ backgroundColor: "#C3C3C3" }));
    const onBulletClick = async (idx) => {
        setNextDeg(nextDeg + 360);
        const nextColor = colors[idx];
        await setText(prefix[idx]);
        await setTextSpring({ color: nextColor.stroke, config: config.slow });
        await setBodySpring({ reset: true, fill: "#ffffff", stroke: nextColor.stroke, onRest: onNextReady });
        await setSpiralColorSpring({ reset: true, fill: nextColor.stroke, stroke: nextColor.stroke });
        setColorSpring({
            borderTop: isMobile ? `2px solid ${nextColor.stroke}` : `1.05px solid ${nextColor.stroke}`,
            backgroundColor: nextColor.stroke,
            config: config.slow,
        });
        await setBulleteSpring({ backgroundColor: nextColor.stroke, config: config.slow });
        await dispatch(Actions.setSessionObject({ [question.id]: idx }));
        setTimeout(async () => {
            onNextReady();
        }, 1000);
    };
    const changeColor = async (idx) => {
        setNextDeg(nextDeg + 360);
        const nextColor = colors[idx];
        await setText(prefix[idx]);
        await setTextSpring({ color: nextColor.stroke, config: config.slow });
        await setBodySpring({ reset: true, fill: "#ffffff", stroke: nextColor.stroke });
        await setSpiralColorSpring({ reset: true, fill: nextColor.stroke, stroke: nextColor.stroke });
        setColorSpring({
            borderTop: isMobile ? `2px solid ${nextColor.stroke}` : `1.05px solid ${nextColor.stroke}`,
            backgroundColor: nextColor.stroke,
            config: config.slow,
        });
        await setBulleteSpring({ backgroundColor: nextColor.stroke, config: config.slow });
    };
    useEffect(() => {
        const loadStuff = async () => {
            await setSpiralColorSpring({
                reset: true,
                fill: currentFill === "- -" ? "#c3c3c3" : currentStroke,
                stroke: currentVal === "- -" ? "#c3c3c3" : currentStroke,
            });
            // await setBodySpring({ reset: true, fill: currentVal === "- -" ? "#ffffff" : currentFill, stroke: currentVal === "- -" ? "#c3c3c3" : currentStroke });
            await setColorSpring({
                borderTop: currentVal === "- -" ? "1.05px solid #C3C3C3" : `1.05px solid ${currentStroke}`,
                backgroundColor: currentVal === "- -" ? "#c3c3c3" : currentStroke,
                config: config.slow,
            });
            await setBulleteSpring({
                backgroundColor: currentVal === "- -" ? "#c3c3c3" : currentStroke,
                config: config.slow,
            });
            await setText(currentVal);
            await setTextSpring({ color: currentVal === "- -" ? "#c3c3c3" : currentStroke, config: config.slow });
            switch (currentVal) {
                case "5 times or more":
                    set({ xy: isMobile ? [270, 0] : [560, 0], config: { duration: 200 } });
                    break;
                case "3-4 times":
                    set({ xy: isMobile ? [205, 0] : [430, 0], config: { duration: 200 } });
                    break;
                case "1-2 times":
                    set({ xy: isMobile ? [140, 0] : [300, 0], config: { duration: 200 } });
                    break;
                case "Maybe once":
                    set({ xy: isMobile ? [75, 0] : [170, 0], config: { duration: 200 } });
                    break;
                case "Never Happened":
                    set({ xy: isMobile ? [10, 0] : [40, 0], config: { duration: 200 } });
                    break;
                default:
                    break;
            }
            if (currentVal === "- -") {
                setBulletAnimationSpring({
                    reset: true,
                    to: [
                        { opacity: 1, transform: "scale(2, 2)" },
                        { opacity: 1, transform: "scale(0, 0)" },
                    ],
                    from: { opacity: 1, transform: "scale(0, 0)" },
                    config: config.wobbly,
                    delay: 150,
                });
            }
            setSmileyAnimationSpring({
                reset: true,
                from: { opacity: 0, transform: "scale(0.1, 0.1)" },
                to: { opacity: 1, transform: "scale(1, 1)" },
                config: config.wobbly,
                delay: 150,
            });
        };
        if (inView) loadStuff();
    }, [question, isVisible, inView]);

    const setSmileyDistance = (d) => {
        if (d < 105) {
            changeColor(0);
        } else if (d < 235) {
            changeColor(1);
        } else if (d < 365) {
            changeColor(2);
        } else if (d < 495) {
            changeColor(3);
        } else {
            changeColor(4);
        }
    };
    const setSmileyDistanceWithAnswer = (d) => {
        if (d < 105) {
            set({ xy: [40, 0], config: { duration: 200 } });
            onBulletClick(0);
        } else if (d < 235) {
            set({ xy: [170, 0], config: { duration: 200 } });
            onBulletClick(1);
        } else if (d < 365) {
            set({ xy: [300, 0], config: { duration: 200 } });
            onBulletClick(2);
        } else if (d < 495) {
            set({ xy: [430, 0], config: { duration: 200 } });
            onBulletClick(3);
        } else {
            set({ xy: [560, 0], config: { duration: 200 } });
            onBulletClick(4);
        }
    };
    const setMobileSmileyDistance = (d) => {
        if (d < 43) {
            changeColor(0);
        } else if (d < 108) {
            changeColor(1);
        } else if (d < 173) {
            changeColor(2);
        } else if (d < 238) {
            changeColor(3);
        } else {
            changeColor(4);
        }
    };
    const setMobileSmileyDistanceWithAnswer = (d) => {
        if (d < 43) {
            set({ xy: [10, 0], config: { duration: 200 } });
            onBulletClick(0);
        } else if (d < 108) {
            set({ xy: [75, 0], config: { duration: 200 } });
            onBulletClick(1);
        } else if (d < 173) {
            set({ xy: [140, 0], config: { duration: 200 } });
            onBulletClick(2);
        } else if (d < 238) {
            set({ xy: [205, 0], config: { duration: 200 } });
            onBulletClick(3);
        } else {
            set({ xy: [270, 0], config: { duration: 200 } });
            onBulletClick(4);
        }
    };
    const [{ xy }, set] = useSpring(() => ({ xy: [isMobile ? 50 : 100, 0] }));
    const bind = useGesture(({ down, delta, velocity, direction, temp = xy.getValue() }) => {
        changeInnerDrag(true);
        if (!isMobile) {
            if (delta[0] + temp[0] < 40) {
                set({
                    xy: [40, 0],
                    immediate: down,
                    config: { mass: velocity, tension: 500 * velocity, friction: 50 },
                });
            } else if (delta[0] + temp[0] > 560) {
                set({
                    xy: [560, 0],
                    immediate: down,
                    config: { mass: velocity, tension: 500 * velocity, friction: 50 },
                });
            } else {
                set({
                    xy: add(delta, temp),
                    immediate: down,
                    config: { mass: velocity, tension: 500 * velocity, friction: 50 },
                });
            }
            if (!down) {
                setSmileyDistanceWithAnswer(delta[0] + temp[0]);
                changeInnerDrag(false);
            }
            setSmileyDistance(delta[0] + temp[0]);
        } else {
            if (delta[0] + temp[0] < 10) {
                set({ xy: [10, 0], config: { mass: velocity, tension: 500 * velocity, friction: 50 } });
            } else if (delta[0] + temp[0] > 270) {
                set({ xy: [270, 0], config: { mass: velocity, tension: 500 * velocity, friction: 50 } });
            } else {
                set({
                    xy: add(delta, temp),
                    immediate: down,
                    config: { mass: velocity, tension: 500 * velocity, friction: 50 },
                });
            }
            if (!down) {
                setMobileSmileyDistanceWithAnswer(delta[0] + temp[0]);
                changeInnerDrag(false);
            }
            setMobileSmileyDistance(delta[0] + temp[0]);
        }
        return temp;
    });

    return (
        <div className={classes.root} ref={ref}>
            <Typography className={classes.prefix}>{question.props.prefix}</Typography>
            <Typography className={classes.title}>{question.title}</Typography>
            <animated.p className={classes.answer} style={textSpring}>
                {text}
            </animated.p>
            <animated.div className={classes.speakers} style={smileyAnimationSpring}>
                <svg
                    width={isMobile ? "117" : "194"}
                    height={isMobile ? "200" : "259"}
                    viewBox="0 0 118 217"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0)">
                        <animated.path
                            style={bodySpring}
                            d="M53.8621 42.9164C65.3793 42.9164 74.7158 33.5799 74.7158 22.0627C74.7158 10.5455 65.3793 1.20892 53.8621 1.20892C42.3448 1.20892 33.0083 10.5455 33.0083 22.0627C33.0083 33.5799 42.3448 42.9164 53.8621 42.9164Z"
                            stroke="#4D64B0"
                            strokeWidth="2.41783"
                        />
                        <animated.path
                            style={bodySpring}
                            d="M27.3023 122.263C27.6167 159.879 28.0579 206.12 28.0579 206.12C28.0579 211.461 32.3879 215.791 37.7292 215.791C43.0705 215.791 47.4005 211.461 47.4005 206.12V144.314C47.4005 140.058 50.851 136.607 55.1074 136.607C57.1513 136.607 59.1116 137.419 60.5569 138.864C62.0022 140.31 62.8142 142.27 62.8142 144.314V206.573C62.8142 211.664 66.9412 215.791 72.0322 215.791C74.4769 215.791 76.8215 214.82 78.5502 213.091C80.2789 211.362 81.2501 209.018 81.2501 206.573V78.0595C81.249 77.7798 81.4399 77.5359 81.7116 77.4698C81.9834 77.4036 82.265 77.5325 82.3925 77.7815L100.925 113.88C102.923 117.772 107.669 119.349 111.6 117.428C115.548 115.493 117.208 110.744 115.323 106.771L90.5406 54.4857C88.9369 51.1065 85.5285 48.9551 81.7881 48.9609H26.0813C22.3128 48.9609 18.8874 51.1502 17.3046 54.5703L9.13237 72.2325L2.96087 85.591C0.227854 91.4941 1.63137 98.4872 6.43045 102.879L29.3273 123.835C32.0032 126.283 36.095 126.317 38.8112 123.914C40.3012 122.595 41.1683 120.711 41.2005 118.722C41.2327 116.733 40.427 114.822 38.9805 113.457L19.8433 95.3832C18.393 94.0135 17.9925 91.8643 18.852 90.064L26.9094 73.1392C26.9094 73.0667 26.9518 85.313 27.1029 102.014"
                            stroke="#4D64B0"
                            strokeWidth="2.41783"
                        />
                        <animated.path
                            style={Object.assign({}, spiralSpring, spiralColorSpring)}
                            opacity="1"
                            d="M53.6012 105.33C49.6009 105.33 46.085 102.14 45.7485 98.2052C45.2438 92.3025 49.7163 87.518 55.7377 87.518C61.7603 87.518 67.0294 92.3025 67.5089 98.2052C68.1469 106.075 62.1892 112.456 54.1995 112.456C46.2099 112.456 39.1791 106.075 38.4943 98.2052C37.6399 88.3685 45.0936 80.3928 55.144 80.3928C65.1944 80.3928 73.9784 88.3685 74.7618 98.2052C74.7618 109.067 65.8326 117.869 54.8148 117.869C43.7982 117.869 34.8677 109.067 34.8677 98.2052C35.7305 108.043 44.5192 116.019 54.4987 116.019C64.4783 116.019 71.9272 108.043 71.1353 98.2052C70.5021 90.3349 63.4761 83.956 55.4408 83.956C47.4068 83.956 41.4431 90.3349 42.1219 98.2052C42.6303 104.108 47.9042 108.894 53.9004 108.894C59.8965 108.894 64.3654 104.108 63.8823 98.2052C63.5603 94.2701 60.0467 91.08 56.0345 91.08C52.0234 91.08 49.0409 94.2701 49.375 98.2052C49.542 100.173 51.3 101.762 53.3007 101.768C55.6091 101.775 57.2313 99.9394 56.6281 98.2052C56.1161 96.7316 53.5711 96.7375 53.0015 98.2052C52.897 95.906 54.3269 94.6432 56.3312 94.6432C58.3356 94.6432 60.0923 96.2376 60.2558 98.2052C60.5802 102.14 57.6014 105.33 53.6012 105.33Z"
                            fill="#CD5C5C"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect x="0.367676" width="117.265" height="217" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </animated.div>
            <div className={classes.stepper}>
                <animated.div style={colorSpring} className={classes.hr} />
                {question.possible_answers.map((a, idx) => {
                    return (
                        <div className={classes.oneStep} key={idx}>
                            <animated.div className={classes.circle} style={colorSpring} />
                        </div>
                    );
                })}
                <animated.div
                    className={classes.answerbullete}
                    {...bind()}
                    style={Object.assign({}, bulleteSpring, {
                        transform: xy.interpolate((x, y) => `translateX(${x}px)`),
                    })}
                >
                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.429249 13.8761L6.48492 7.95136L0.429248 1.99389C-0.389086 1.20829 0.952982 -0.133781 1.77132 0.684553L8.67806 7.39489C8.97266 7.68949 8.97266 8.18049 8.67806 8.44236L1.77132 15.2182C0.952983 16.0038 -0.389085 14.6617 0.429249 13.8761Z"
                            fill="white"
                        />
                    </svg>
                </animated.div>
                <animated.div className={classes.animationbullete} style={bulletAnimationSpring}>
                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.429249 13.8761L6.48492 7.95136L0.429248 1.99389C-0.389086 1.20829 0.952982 -0.133781 1.77132 0.684553L8.67806 7.39489C8.97266 7.68949 8.97266 8.18049 8.67806 8.44236L1.77132 15.2182C0.952983 16.0038 -0.389085 14.6617 0.429249 13.8761Z"
                            fill="white"
                        />
                    </svg>
                </animated.div>
            </div>
            <div className={classes.steppertext}>
                <Typography className={classes.helptext} style={{ color: `${question.props.color}` }}>
                    never happened
                </Typography>
                <div style={{ flexGrow: 1 }}></div>
                <Typography className={classes.helptext} style={{ color: `${question.props.color}` }}>
                    5 times or more
                </Typography>
            </div>
            {!isMobile && (
                <Typography style={{ color: "#c3c3c3", opacity: 0.7 }} className={classes.slideText}>
                    Slide to your answer
                </Typography>
            )}
        </div>
    );
}
