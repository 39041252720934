import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import FormPage from '../FormPage/FormPage';
import Header from '../Header';
import StyledButton from '../../StyledButton';
import * as Actions from '../../../redux/actions';
import clamp from 'lodash-es/clamp';
import { useSprings, animated, config, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import '../../QuestionPager.css';
import ExistingSessionDialog from '../../ExistingSessionDialog';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import { useHistory } from "react-router-dom";

import BubblesQuestionView from '../BubblesQuestionView';
import BulletsQuestionView from '../BulletsQuestionView';
import PersonKindQuestionView from '../PersonKindQuestionView';
import ImageSmileyQuestionView from '../ImageSmileyQuestionView';
import FlavourSelectionView from '../FlavourSelectionView';
import EmailView from '../EmailView';
//
import AgeView from '../AgeView';
//
import ImageManQuestionView from '../ImageManQuestionView';
import HeartQuestionView from '../HeartQuestionView';
import ThroatQuestionView from '../ThroatQuestionView';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        overflow: 'hidden',
        width: '100%',
        height: '100%'
    },
    flexRow: {
        width: '100%',
        position: 'absolute',
        bottom: isMobile? '4vh': '24px',
        left: isMobile? 'calc(50% - 64px)': undefined,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row'
    },
    flexGrow: {
        flexGrow: 1
    },
    nextButton: {
        alignSelf: 'flex-end',
        marginRight: '20px'
    },
    backButton: {
        alignSelf: 'flex-start',
        margin: 'auto',
        marginLeft: isMobile ? '20px' : '160px'
    },
    progress: {
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100%',
        height: isMobile ? '12px' : '15px',
        borderRadius: '10px',
    },
    progressP: {
        height: isMobile ? '10px' : '13px',
        width: '90%',
        backgroundColor: 'pink',
        borderRadius: '10px',
        fontFamily: 'Lato',
        fontSize: '10px',
        textAlign: 'center',
        opacity: 0.6,
    },
    backarrow: {
        display: isMobile ? 'none' : undefined,
        position: 'fixed',
        top: '46%',
        left: '40px',
        width: "50px",
		height: "50px",
		borderRadius: "50%",
		border: "1px solid #c3c3c3",
        paddingTop: '12px',
        paddingLeft: '12px',
    },
    forwardarrow: {
        display: isMobile ? 'none' : undefined,
        position: 'fixed',
        top: '46%',
        right: '40px',
        width: "50px",
		height: "50px",
		borderRadius: "50%",
		border: "1px solid #c3c3c3",
        paddingTop: '12px',
        paddingLeft: '12px',
    },
}));

const questionTypeToFragment = (q, onNextReady, changeInnerDrag) => {
    let retFragment = <p>Not implemented yet</p>;
    
    switch(q.type) {
        case 'person':
            retFragment = <PersonKindQuestionView question={q} onNextReady={onNextReady} />;
            break;
        case 'bubble':
            retFragment = <BubblesQuestionView question={q} onNextReady={onNextReady} />;
            break;
        case 'bullets':
            retFragment = <BulletsQuestionView question={q} onNextReady={onNextReady} />;
            break;
        case 'image_smiley':
            retFragment = <ImageSmileyQuestionView question={q} onNextReady={onNextReady} changeInnerDrag={changeInnerDrag} />;
            break;
        case 'flavour':
            retFragment = <FlavourSelectionView question={q} onNextReady={onNextReady} />;
            break;
        case 'stomach':
            retFragment = <ImageManQuestionView question={q} onNextReady={onNextReady} changeInnerDrag={changeInnerDrag} />;
            break;
        case 'heart':
            retFragment = <HeartQuestionView question={q} onNextReady={onNextReady} />;
            break;
        case 'throat':
            retFragment = <ThroatQuestionView question={q} onNextReady={onNextReady} />;
            break;
        case 'tube':
        default:
            retFragment = <BubblesQuestionView question={q} onNextReady={onNextReady} />;
            break;
    }
    return retFragment;
};

export default function MainView() {
    const session = localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')) : {};
//    const session = useSelector(state => state.main.session);
    const classes = useStyles();
    const dispatch = useDispatch();
    const questionnaire = useSelector(state => state.main.questionnaire);
    const questions = questionnaire && questionnaire.questions || undefined;
    const currPageIndex = useSelector(state => state.main.currentQuestionIndex);
    const [phase, setPhase] = useState("intro");
    const currQ = phase !== "intro" && questionnaire && questionnaire.questions[currPageIndex - 1];
    const email = useSelector(state => state.main.session.email) || session.email;
    const password = useSelector(state => state.main.session.password) || '';
    const [all, setAll] = useState([]);
    const [showExistingSessionDialog, setShowExistingSessionDialog] = useState(false);
    const [redirect, setRedirect] = useState(undefined);
    const [emailButton, setEmailButton] = useState(false);
    const [emailDialog, setEmailDialog] = useState(false);
    const handleEmailDialogClose = () => {
        setEmailDialog(false);
    };
    const showNextButton = e => {
        setEmailButton(e);
    };
    const [innerDrag, setInnerDrag] = useState(false);
    const changeInnerDrag = e => {
        setInnerDrag(e);
    };
    const [progressSpring, setProgressSpring] = useSpring(() => ({ 
        from: {width: '100%', backgroundColor: '#4D64B0'},
        to: {width: '0%', backgroundColor: '#4D64B0'},
        config: config.slow 
    }));

    const history = useHistory();
    const onClickBackButton = async () => {
        await dispatch(Actions.setCurrentQuestionIndex(localStorage.getItem('currentQ') - 1));
        localStorage.setItem('currentQ', localStorage.getItem('currentQ') - 1);
        if(localStorage.getItem('currentQ') < 0) {
            history.push("/");
        };
    };
    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onClickBackButton);
        localStorage.setItem('currentQ', 0);
    }, []);   
    
    const onNextPressed = async (nextIndex) => {
        let nextPhase = 'questions';
        if (phase === 'email') {
            let err = false;
            if (!email) {
                alert('error finding email');
            } else if(email && password) {
                try {
                    await dispatch(Actions.authenticateUser(email, password));
                } catch(e) {
                    err = true;
                    console.log('error in authentication');
                    setEmailDialog(true);
                }
            } else {
                try {
                    await dispatch(Actions.createUser(email, session.first_name, session.last_name, session.gender));
                    await dispatch(Actions.updateUser(session.first_name, session.last_name, session.gender));
                } catch(e) {
                    err = true;
                    console.log('error when creating user', e);
                }
            }
            if (err) return;
            await setRedirect('/calc');
            } else if (nextIndex === (questionnaire.questions.length) && localStorage.getItem('access_token'))
               {
                if (localStorage.getItem('access_token')) {
                    return (
                        setRedirect('/calc')

                    )
                } else { 
                    nextPhase = 'email';
                }
            }
            else if (nextIndex === (questionnaire.questions.length + 2) && (!localStorage.getItem('access_token')))   // 2 >< 1
               {
                if (localStorage.getItem('access_token')) {
                    return await setRedirect('/calc');
                } else { 
                    nextPhase = 'email';
                }
            }
        console.log('next', nextIndex, phase, nextPhase, questionnaire.questions.length, localStorage.getItem('access_token'));
        if(nextIndex === (Object.values(JSON.parse(localStorage.getItem('session')))).length) {
            await dispatch(Actions.setCurrentQuestionIndex(nextIndex+1));
        };
        await setPhase(nextPhase);
        await dispatch(Actions.setCurrentQuestionIndex(nextIndex));
        localStorage.setItem('currentQ', nextIndex);
        if(nextIndex <= questionnaire.questions.length) {
            await setProgressSpring({
                from: {width: '1%'},
                to: {width: `${nextIndex / questionnaire.questions.length * 100}%`, backgroundColor: questions[nextIndex-1].props.color},
                config: config.stiff 
            });
        };
        if(nextIndex === questionnaire.questions.length + 1) {
            console.log("is complete");
            console.log(nextIndex);
            localStorage.setItem('questionnaireNum', '2');
        }
    }

    useEffect(() => {
        const loadStuff = async () => {
            let movement = 0;
            let ageIndex = 0
            if (!questionnaire) {
                await dispatch(Actions.getQuestionnaire());
            } else {  
                const isPrimary = questionnaire.type === 'primary';
                if (localStorage.getItem('session')) {
                    await setShowExistingSessionDialog(true);
                }
                let a = [];
                if (isPrimary && !localStorage.getItem('current_user')) {
                    a.push(<FormPage onNextReady={() => {onNextPressed(1)}} />);
                    movement = 1;
                }
                for (let i = 0; i < questionnaire.questions.length; i++) {
                    a.push(questionTypeToFragment(questions[i], () => {onNextPressed(i + 1 + movement);}, changeInnerDrag));
                    ageIndex = i + 1 + movement;
                }
                if (isPrimary && !localStorage.getItem('current_user')) {
                    a.push(<AgeView onNextReady={() => {onNextPressed(ageIndex + 1)}} />);
                }
                if (!localStorage.getItem('access_token')) {
                    a.push(<EmailView showNextButton={showNextButton} />);
                }
                await setAll(a);
            }
        };
        loadStuff();
    }, [questionnaire]);
        
    useEffect(() => {
        index.current = currPageIndex;
        localStorage.setItem('currentQ', currPageIndex);
        const move = async() => {            
            await set(index => {
                return { x: (index - currPageIndex) * window.innerWidth, sc: 1, display: 'block',  config: config.slow };
            });
            if(currPageIndex > -1) window.history.pushState(null, null,window.location.pathname);
        };
        move();
    }, [currPageIndex]);    
    
    const index = useRef(currPageIndex);
    const [props, set] = useSprings(all.length, i => ({ x: (i - currPageIndex) * window.innerWidth, sc: 1, display: 'block', config: config.slow }));
    
    const bind = useDrag(({ down, delta: [xDelta, yDelta], direction: [xDir], distance, movement, cancel }) => {
        if(innerDrag) return;
        const xMoved = Math.abs(movement[0]) > Math.abs(movement[1]);
        if (!xMoved || distance < 10) return;
        if (down && distance > window.innerWidth / 5)
            cancel((index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, all.length - 1)));
        set(i => {
            if (i < index.current - 1 || i > index.current + 1) return { display: 'none' };
            const x = (i - index.current) * window.innerWidth + (down ? xDelta : 0);
            const sc = down ? 1 - distance / window.innerWidth / 2 : 1;
            return { x, sc, display: 'block' };
        });
    });
    const onDeleteSession = async () => {
        await setShowExistingSessionDialog(false);
        await setPhase('intro');
        await dispatch(Actions.setSessionObject({}));
        await dispatch(Actions.setCurrentQuestionIndex(0));
        localStorage.removeItem('session');
    };

    const onUseSession = async () => {
        const session = localStorage.getItem('session') ? JSON.parse(localStorage.getItem('session')) : {};
        await dispatch(Actions.setSessionObject(session));
        
        let curr = 0;
        // if name / gender are missing go to first page.
        let nextPhase = questionnaire.type === 'primary' ? 'intro' : 'questions';
        let broke = false;
        if (session.username !== undefined && session.gender !== undefined) {
            nextPhase = 'questions';
            curr++;
            for (let i = 0; i < questionnaire.questions.length; i++) {
                const q = questionnaire.questions[i];
                if (session[q.id] !== undefined) {
                    curr++;
                } else {
                    broke = true;
                    break;
                }
            }
        }        
        if (nextPhase === 'questions' && !broke) 
            nextPhase = 'email';
        await dispatch(Actions.setCurrentQuestionIndex(curr));
        await setPhase(nextPhase);        
        await setShowExistingSessionDialog(false);
    };

    const backArrowClicked = async () => {
        await dispatch(Actions.setCurrentQuestionIndex(currPageIndex - 1));
        localStorage.setItem('currentQ', localStorage.getItem('currentQ') - 1);
    };

    const forwardArrowClicked = async () => {
        await dispatch(Actions.setCurrentQuestionIndex(currPageIndex + 1));
        localStorage.setItem('currentQ', localStorage.getItem('currentQ') + 1);
    };

    const localStyle = { 
        backgroundColor: `${currQ && currQ.props.color}`
    };

    return redirect ? <Redirect to={redirect} /> : (
        <div className={classes.root}>
            <Header />
            <ExistingSessionDialog 
                open = {showExistingSessionDialog}
                onClose={onUseSession}
                onDelete={onDeleteSession}
            />
            {props.map(({ x, display, sc }, i) => (
                <animated.div className="animatedDiv" {...bind()} key={i} style={{ display, transform: x.interpolate(x => `translate3d(${x}px,0,0)`), position: 'absolute', 'width': '100%', 'height': '100%' }}>
                    <animated.div style={{transform: sc.interpolate(s => `scale(${s})`) }}>
                        {all[i]}
                    </animated.div>
                </animated.div>        
            ))}
            {/* {phase === 'email' &&  <div className={classes.flexRow}>
                {!isMobile && <div className={classes.flexGrow} />}
                {((emailButton && isMobile) || (!isMobile)) && 
                <StyledButton style={localStyle} disabled={email === undefined || email === ''} uppercase="false" variant="contained" className={classes.nextButton} onClick={() => onNextPressed(questionnaire.questions.length + 2)}>
                    Next
                </StyledButton>}
            </div>} */}
            {currPageIndex !== 0 && 
                <div className={classes.backarrow} onClick={backArrowClicked}><ArrowBackIcon /></div>
            }
            {localStorage.getItem('session') && (((localStorage.getItem('session').indexOf('gender') === -1) && (currPageIndex < Object.values(JSON.parse(localStorage.getItem('session'))).length)) || (localStorage.getItem('session').indexOf('gender') && (localStorage.getItem('session').indexOf('last_name') === -1) && (currPageIndex < Object.values(JSON.parse(localStorage.getItem('session'))).length - 1)) || (localStorage.getItem('session').indexOf('gender') && localStorage.getItem('session').indexOf('last_name') &&(currPageIndex < Object.values(JSON.parse(localStorage.getItem('session'))).length - 2))) && phase !== 'email' &&
                <div className={classes.forwardarrow} onClick={forwardArrowClicked}><ArrowForwardIcon /></div>
            }
            {(phase === 'email' ||  (isMobile && currPageIndex === 0) || phase === 'intro') ? undefined : <div className={classes.progress}>
                <animated.div className={classes.progressP} style={progressSpring}> </animated.div>
            </div>}
            <div>
                <Dialog open={emailDialog} onClose={handleEmailDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                    <DialogTitle>{"Authentication failed"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            incorrect credentials, please try again or contact us 
                            at support@myair.ai
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEmailDialogClose} color="primary" autoFocus>
                            close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}


    


