import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar, Container } from "@material-ui/core";
import Logo from "./images/myairlogo.svg";
import Profile from "./images/Profile.svg";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    header: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: isMobile ? "16vh" : "12vh",
    },
    toolbar: {
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
    },
    logo: {
        marginTop: "20px",
    },
    mobileLogo: {
        backgroundSize: "cover",
        backgroundImage: `url(${Logo})`,
        height: "14vh",
        width: "42vw",
        backgroundRepeat: "no-repeat",
    },
    invisible: {
        width: "30px",
    },
    dashboardIcon: {
        cursor: "pointer",
    },
}));

export default function Header(props) {
    const classes = useStyles();
    const history = useHistory();

    const routeChange = () => {
        let path = `dashboard`;
        history.push(path);
    };

    return (
        <React.Fragment>
            <Container className={classes.header}>
                <Toolbar className={classes.toolbar}>
                    {isMobile ? (
                        <div className={classes.mobileLogo}></div>
                    ) : (
                        <React.Fragment>
                            <div className={classes.invisible}></div>
                            <div className={classes.logo}>
                                <img src={Logo} alt="logo" />
                            </div>
                            <img className={classes.dashboardIcon} onClick={routeChange} src={Profile} alt="profile" />
                        </React.Fragment>
                    )}
                </Toolbar>
            </Container>
        </React.Fragment>
    );
}
