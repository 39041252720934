import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import { config, useSpring, animated } from "react-spring";
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "../../redux/actions";
import { isMobile } from "react-device-detect";
import { useGesture } from "react-with-gesture";
import { add } from "vec-la";
import arrow from "./images/arrow.svg";
import { useInView } from "react-intersection-observer";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
        minHeight: "540px",
    },
    title: {
        marginLeft: isMobile ? "25px" : undefined,
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: isMobile ? "22px" : "30px",
        lineHeight: isMobile ? "22px" : "108.1%",
        color: "#567284",
        alignSelf: isMobile ? "start" : "center",
        maxWidth: isMobile ? "320px" : undefined,
    },
    prefix: {
        marginLeft: isMobile ? "25px" : undefined,
        marginTop: isMobile ? undefined : "20px",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: isMobile ? "22px" : "30px",
        lineHeight: isMobile ? "22px" : "108.1%",
        color: "#567284",
        alignSelf: isMobile ? "start" : "center",
        maxWidth: isMobile ? "320px" : undefined,
    },
    flexGrow: {
        flexGrow: 1,
    },
    speakers: {
        height: isMobile ? "150px" : "260px",
        marginTop: isMobile ? "50px" : "0px",
    },
    answer: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "30px",
        lineHeight: "22px",
        textAlign: "center",
        letterSpacing: "0.6px",
        marginTop: "24px",
        alignSelf: "center",
    },
    slideText: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "28px",
        textAlign: "center",
        margin: isMobile ? "auto" : "0px 0px",
    },
    stepper: {
        display: "flex",
        flexDirection: "row",
        minWidth: isMobile ? "120px" : "260px",
        alignItems: "center",
        marginTop: isMobile ? "52px" : "10px",
    },
    oneStep: {
        display: "flex",
        flexDirection: "column",
        minHeight: "82px",
        minWidth: isMobile ? "64px" : "128px",
        alignItems: "center",
        alignSelf: "auto",
        flex: "0 1 auto",
        justifyContent: "center",
    },
    circle: {
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        backgroundColor: "#C4C4C4",
    },
    stepLabel: {
        width: "120px",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "normal !important",
        fontSize: "16px",
        lineHeight: "22px",
        textAlign: "center",
        letterSpacing: "0.61px",
    },
    bullet: {
        margin: isMobile ? "0px 12px" : "0px 25px",
        height: "64px",
        width: "64px",
        backgroundRepeat: "no-repeat",
        zIndex: "20",
        textAlign: "center",
        "&::after": {
            display: "inline-block",
            height: "64px",
            width: "64px",
            content: "''",
            background: `url(${arrow}) no-repeat 28px 20px`,
            left: "28px",
        },
    },
    hr: {
        position: "absolute",
        width: isMobile ? "260px" : "510px",
        marginLeft: isMobile ? "32px" : "64px",
        marginTop: "1px",
        zIndex: "-99",
        opacity: isMobile ? 0.7 : 1,
    },
    steppertext: {
        display: "flex",
        flexDirection: "row",
        height: "40px",
        width: isMobile ? "300px" : "670px",
        minWidth: isMobile ? "120px" : "260px",
    },
    helptext: {
        width: isMobile ? "50px" : "150px",
        fontFamily: "Lato",
        fontWeight: "bold",
        fontSize: isMobile ? "12px" : "16px",
        lineHeight: isMobile ? "13px" : "22px",
        textAlign: "center",
        letterSspacing: isMobile ? "0.319649px" : "0.607132px",
    },
    answerbullete: {
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: isMobile ? "30px" : "40px",
        height: isMobile ? "30px" : "40px",
        borderRadius: "50%",
        backgroundColor: "#C73E76",
        cursor: "-webkit-grab",
        whiteSpace: "pre",
        boxShadow: "1px 1px 14px rgba(0, 0, 0, 0.05)",
        zIndex: 1,
    },
    animationbullete: {
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: isMobile ? "30px" : "40px",
        height: isMobile ? "30px" : "40px",
        borderRadius: "50%",
        backgroundColor: "#c4c4c4",
        cursor: "-webkit-grab",
        whiteSpace: "pre",
        boxShadow: "1px 1px 14px rgba(0, 0, 0, 0.05)",
        zIndex: 1,
        left: isMobile ? "calc(50% - 110px)" : "calc(50% - 220px)",
    },
}));
const colors = [
    { fill: "#FCF5DE", stroke: "#EABA00", mouthD: 0 },
    { fill: "#F4F7FF", stroke: "#4D64B0", mouthD: 1 },
    { fill: "#DCEDDC", stroke: "#307B26", mouthD: 2 },
    { fill: "#E6DDEB", stroke: "#896DA0", mouthD: 3 },
    { fill: "#FFF0F6", stroke: "#C73E76", mouthD: 4 },
];
const prefix = ["never happened", "maybe once", "1-2 times", "3-4 times", "5 times or more", "- -"];

export default function ImageSmileyQuestionView(props) {
    const { question, onNextReady, isVisible, changeInnerDrag } = props;
    const [ref, inView] = useInView({
        threshold: 0,
    });
    const classes = useStyles();
    const answer = useSelector((state) => state.main.session[question.id]);
    const currentVal = (answer !== undefined && question.possible_answers[answer]) || "- -";
    const currentStroke = (answer !== undefined && colors[answer].stroke) || "#C3C3C3";
    const currentFill = (answer !== undefined && colors[answer].fill) || "#FFFFFF";
    const currentMouth = (answer !== undefined && colors[answer].mouthD) || 5;
    const dispatch = useDispatch();
    const [text, setText] = useState(prefix[5]);
    const [mouthNumber, setMouthNumber] = useState(5);
    const [bodySpring, setBodySpring] = useSpring(() => ({
        fill: currentFill,
        stroke: currentStroke,
        config: config.slow,
    }));
    const [colorSpring, setColorSpring] = useSpring(() => ({
        borderTop: `1.05px solid #C3C3C3`,
        backgroundColor: "#C3C3C3",
        config: config.slow,
    }));
    const [bulleteSpring, setBulleteSpring] = useSpring(() => ({ backgroundColor: "#C3C3C3", config: config.slow }));
    const [textSpring, setTextSpring] = useSpring(() => ({ color: "#C3C3C3", config: config.slow }));
    const [bulletAnimationSpring, setBulletAnimationSpring] = useSpring(() => ({
        reset: true,
        to: [
            { opacity: 1, transform: "scale(2.5, 2.5)" },
            { opacity: 1, transform: "scale(2.5, 2.5)" },
            { opacity: 0, transform: "scale(0, 0)" },
        ],
        from: { opacity: 1, transform: "scale(0, 0)" },
        config: config.wobbly,
    }));
    const [smileyAnimationSpring, setSmileyAnimationSpring] = useSpring(() => ({
        reset: true,
        from: { opacity: 1, transform: "scale(0.5, 0.5)" },
        to: { opacity: 1, transform: "scale(1, 1)" },
        config: config.wobbly,
        delay: 200,
    }));

    const onBulletClick = async (idx) => {
        const nextColor = colors[idx];
        await setText(prefix[idx]);
        await setBodySpring({
            reset: true,
            fill: nextColor.fill,
            stroke: nextColor.stroke,
            config: config.slow,
            onRest: onNextReady,
        });
        await setColorSpring({
            reset: true,
            borderTop: isMobile ? `2px solid ${nextColor.stroke}` : `1.05px solid ${nextColor.stroke}`,
            backgroundColor: nextColor.stroke,
            config: config.slow,
        });
        await setBulleteSpring({ backgroundColor: nextColor.stroke, config: config.slow });
        await setTextSpring({ color: nextColor.stroke, config: config.slow });
        await setMouthNumber(idx);
        await dispatch(Actions.setSessionObject({ [question.id]: idx }));
        setTimeout(async () => {
            onNextReady();
        }, 1000);
    };

    const mouthArray = [
        <animated.path
            d="M287.957 150.511C287.957 164.925 282.231 178.75 272.038 188.943C261.846 199.135 248.021 204.862 233.606 204.862C219.192 204.862 205.367 199.135 195.174 188.943C184.982 178.75 179.255 164.925 179.255 150.511"
            stroke="#EABA00"
            strokeWidth="2.78723"
            strokeLinecap="round"
        />,
        <animated.path
            d="M175.245 157.979C175.245 157.979 194.394 205.372 232.691 205.372"
            stroke="#4D64B0"
            strokeWidth="2.87234"
            strokeLinecap="round"
        />,
        <animated.path d="M183.278 190.926H284.179" stroke="#307B26" strokeWidth="6.60684" strokeLinecap="round" />,
        <animated.line
            x1="210.585"
            y1="191.011"
            x2="260.851"
            y2="191.011"
            stroke="#896DA0"
            strokeWidth="2.87234"
            strokeLinecap="round"
        />,
        <animated.path d="M175.144 179.708H290.694" stroke="#C73E76" strokeWidth="9.62912" strokeLinecap="round" />,
        <animated.line
            x1="210.585"
            y1="191.011"
            x2="260.851"
            y2="191.011"
            stroke="#c3c3c3"
            strokeWidth="2.87234"
            strokeLinecap="round"
        />,
    ];
    const lefteyesArray = [
        <animated.path
            d="M187.7 119.433V82.6415H186.92H186.14V119.433H186.92H187.7Z"
            stroke="#EABA00"
            strokeWidth="1.56037"
            strokeLinecap="round"
        />,
        <animated.path
            d="M187.7 119.433V82.6415H186.92H186.14V119.433H186.92H187.7Z"
            stroke="#4D64B0"
            strokeWidth="1.56037"
            strokeLinecap="round"
        />,
        <animated.path d="M184.376 84.9328V123.118" stroke="#307B26" strokeWidth="6.55009" strokeLinecap="round" />,
        <animated.path
            d="M187.7 119.433V82.6415H186.92H186.14V119.433H186.92H187.7Z"
            stroke="#896DA0"
            strokeWidth="1.56037"
            strokeLinecap="round"
        />,
        <animated.path />,
        <animated.path
            d="M187.7 119.433V82.6415H186.92H186.14V119.433H186.92H187.7Z"
            stroke="#c3c3c3"
            strokeWidth="1.56037"
            strokeLinecap="round"
        />,
    ];
    const righteyesArray = [
        <animated.path
            d="M281.073 119.433V82.6415H280.293H279.512V119.433H280.293H281.073Z"
            stroke="#EABA00"
            strokeWidth="1.56037"
            strokeLinecap="round"
        />,
        <animated.path
            d="M281.073 119.433V82.6415H280.293H279.512V119.433H280.293H281.073Z"
            stroke="#4D64B0"
            strokeWidth="1.56037"
            strokeLinecap="round"
        />,
        <animated.path d="M279.842 84.9328V123.118" stroke="#307B26" strokeWidth="6.55009" strokeLinecap="round" />,
        <animated.path
            d="M281.073 119.433V82.6415H280.293H279.512V119.433H280.293H281.073Z"
            stroke="#896DA0"
            strokeWidth="1.56037"
            strokeLinecap="round"
        />,
        <animated.path />,
        <animated.path
            d="M281.073 119.433V82.6415H280.293H279.512V119.433H280.293H281.073Z"
            stroke="#c3c3c3"
            strokeWidth="1.56037"
            strokeLinecap="round"
        />,
    ];

    const changeColor = async (idx) => {
        const nextColor = colors[idx];
        await setText(prefix[idx]);
        await setBodySpring({
            reset: true,
            fill: nextColor.fill,
            stroke: nextColor.stroke,
            config: { duration: 1000 },
            onRest: onNextReady,
        });
        await setColorSpring({
            reset: true,
            borderTop: isMobile ? `2px solid ${nextColor.stroke}` : `1.05px solid ${nextColor.stroke}`,
            backgroundColor: nextColor.stroke,
            config: config.slow,
        });
        await setBulleteSpring({ backgroundColor: nextColor.stroke, config: config.slow });
        await setTextSpring({ color: nextColor.stroke, config: config.slow });
        await setMouthNumber(idx);
    };
    const setSmileyDistance = (d) => {
        if (d < 105) {
            changeColor(0);
        } else if (d < 235) {
            changeColor(1);
        } else if (d < 365) {
            changeColor(2);
        } else if (d < 495) {
            changeColor(3);
        } else {
            changeColor(4);
        }
    };
    const setSmileyDistanceWithAnswer = (d) => {
        if (d < 105) {
            set({ xy: [40, 0], config: { duration: 200 } });
            onBulletClick(0);
        } else if (d < 235) {
            set({ xy: [170, 0], config: { duration: 200 } });
            onBulletClick(1);
        } else if (d < 365) {
            set({ xy: [300, 0], config: { duration: 200 } });
            onBulletClick(2);
        } else if (d < 495) {
            set({ xy: [430, 0], config: { duration: 200 } });
            onBulletClick(3);
        } else {
            set({ xy: [560, 0], config: { duration: 200 } });
            onBulletClick(4);
        }
    };
    const setMobileSmileyDistance = (d) => {
        if (d < 43) {
            changeColor(0);
        } else if (d < 108) {
            changeColor(1);
        } else if (d < 173) {
            changeColor(2);
        } else if (d < 238) {
            changeColor(3);
        } else {
            changeColor(4);
        }
    };
    const setMobileSmileyDistanceWithAnswer = (d) => {
        if (d < 43) {
            set({ xy: [10, 0], config: { duration: 200 } });
            onBulletClick(0);
        } else if (d < 108) {
            set({ xy: [75, 0], config: { duration: 200 } });
            onBulletClick(1);
        } else if (d < 173) {
            set({ xy: [140, 0], config: { duration: 200 } });
            onBulletClick(2);
        } else if (d < 238) {
            set({ xy: [205, 0], config: { duration: 200 } });
            onBulletClick(3);
        } else {
            set({ xy: [270, 0], config: { duration: 200 } });
            onBulletClick(4);
        }
    };
    const [{ xy }, set] = useSpring(() => ({ xy: [isMobile ? 50 : 100, 0] }));
    const bind = useGesture(({ down, delta, velocity, direction, temp = xy.getValue() }) => {
        changeInnerDrag(true);
        if (!isMobile) {
            if (delta[0] + temp[0] < 40) {
                set({
                    xy: [40, 0],
                    immediate: down,
                    config: { mass: velocity, tension: 500 * velocity, friction: 50 },
                });
            } else if (delta[0] + temp[0] > 560) {
                set({
                    xy: [560, 0],
                    immediate: down,
                    config: { mass: velocity, tension: 500 * velocity, friction: 50 },
                });
            } else {
                set({
                    xy: add(delta, temp),
                    immediate: down,
                    config: { mass: velocity, tension: 500 * velocity, friction: 50 },
                });
            }
            if (!down) {
                setSmileyDistanceWithAnswer(delta[0] + temp[0]);
                changeInnerDrag(false);
            }
            setSmileyDistance(delta[0] + temp[0]);
        } else {
            if (delta[0] + temp[0] < 10) {
                set({ xy: [10, 0], config: { mass: velocity, tension: 500 * velocity, friction: 50 } });
            } else if (delta[0] + temp[0] > 270) {
                set({ xy: [270, 0], config: { mass: velocity, tension: 500 * velocity, friction: 50 } });
            } else {
                set({
                    xy: add(delta, temp),
                    immediate: down,
                    config: { mass: velocity, tension: 500 * velocity, friction: 50 },
                });
            }
            if (!down) {
                setMobileSmileyDistanceWithAnswer(delta[0] + temp[0]);
                changeInnerDrag(false);
            }
            setMobileSmileyDistance(delta[0] + temp[0]);
        }
        return temp;
    });

    useEffect(() => {
        const loadStuff = async () => {
            // await setBodySpring({ reset: true, fill: currentVal === "- -" ? "#ffffff" : currentFill, stroke: currentVal === "- -" ? "#c3c3c3" : currentStroke });
            setColorSpring({
                borderTop: currentVal === "- -" ? "1.05px solid #C3C3C3" : `1.05px solid ${currentStroke}`,
                backgroundColor: currentVal === "- -" ? "#c3c3c3" : currentStroke,
                config: config.slow,
            });
            setBulleteSpring({
                backgroundColor: currentVal === "- -" ? "#c3c3c3" : currentStroke,
                config: config.slow,
            });
            setText(currentVal);
            setTextSpring({ color: currentVal === "- -" ? "#c3c3c3" : currentStroke, config: config.slow });
            switch (currentVal) {
                case "5 times or more":
                    set({ xy: isMobile ? [270, 0] : [560, 0], config: { duration: 200 } });
                    setMouthNumber(4);
                    break;
                case "3-4 times":
                    set({ xy: isMobile ? [205, 0] : [430, 0], config: { duration: 200 } });
                    setMouthNumber(3);
                    break;
                case "1-2 times":
                    set({ xy: isMobile ? [140, 0] : [300, 0], config: { duration: 200 } });
                    setMouthNumber(2);
                    break;
                case "Maybe once":
                    set({ xy: isMobile ? [75, 0] : [170, 0], config: { duration: 200 } });
                    setMouthNumber(1);
                    break;
                case "Never Happened":
                    set({ xy: isMobile ? [10, 0] : [40, 0], config: { duration: 200 } });
                    setMouthNumber(0);
                    break;
                default:
                    setMouthNumber(5);
                    break;
            }
            if (currentVal === "- -") {
                setBulletAnimationSpring({
                    reset: true,
                    to: [
                        { opacity: 1, transform: "scale(2, 2)" },
                        { opacity: 1, transform: "scale(0, 0)" },
                    ],
                    from: { opacity: 1, transform: "scale(0, 0)" },
                    config: config.wobbly,
                    delay: 150,
                });
            }
            setSmileyAnimationSpring({
                reset: true,
                from: { opacity: 0, transform: "scale(0.1, 0.1)" },
                to: { opacity: 1, transform: "scale(1, 1)" },
                config: config.wobbly,
                delay: 150,
            });
        };
        if (inView) loadStuff();
    }, [question, isVisible, inView]);

    return (
        <div className={classes.root} ref={ref}>
            <Typography className={classes.prefix}>{question.props.prefix}</Typography>
            <Typography className={classes.title}>{question.title}</Typography>
            <animated.p className={classes.answer} style={textSpring}>
                {text}
            </animated.p>
            <animated.div className={classes.speakers} style={smileyAnimationSpring}>
                <svg
                    width={isMobile ? "515" : "466"}
                    height={isMobile ? "149" : "282"}
                    viewBox="0 0 466 282"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g filter="url(#filter0_d)">
                        <animated.circle
                            style={bodySpring}
                            cx="233"
                            cy="137"
                            r="135.5"
                            fill="#DCEDDC"
                            stroke="#307B26"
                            strokeWidth="3"
                        />
                        {mouthArray[mouthNumber]}
                        {lefteyesArray[mouthNumber]}
                        {righteyesArray[mouthNumber]}
                    </g>
                    <animated.path
                        style={bodySpring}
                        d="M64.7306 62.1646C65.192 61.861 65.8121 61.989 66.1157 62.4504C66.4192 62.9117 66.2913 63.5318 65.8299 63.8354L64.7306 62.1646ZM34.5219 96.2685C34.0225 96.5045 33.4265 96.291 33.1905 95.7917C32.9545 95.2923 33.168 94.6963 33.6674 94.4603L34.5219 96.2685ZM51.6422 85.499L52.2815 84.73L51.6422 85.499ZM51.4302 87.1721L51.8574 88.0762L51.4302 87.1721ZM43.6875 77.2069L44.2372 78.0423L43.6875 77.2069ZM43.5979 78.8113L44.2372 78.0423L43.5979 78.8113ZM65.8299 63.8354L44.2372 78.0423L43.1379 76.3715L64.7306 62.1646L65.8299 63.8354ZM44.2372 78.0423L52.2815 84.73L51.0029 86.268L42.9586 79.5803L44.2372 78.0423ZM51.8574 88.0762L34.5219 96.2685L33.6674 94.4603L51.0029 86.268L51.8574 88.0762ZM52.2815 84.73C53.4061 85.665 53.1797 87.4514 51.8574 88.0762L51.0029 86.268L52.2815 84.73ZM44.2372 78.0423L42.9586 79.5803C41.9259 78.7217 42.016 77.1097 43.1379 76.3715L44.2372 78.0423Z"
                        fill="#307B26"
                    />
                    <animated.path
                        style={bodySpring}
                        d="M76.1821 110.045C76.7252 110.146 77.0839 110.667 76.9833 111.21C76.8827 111.753 76.3609 112.112 75.8179 112.011L76.1821 110.045ZM30.7715 113.722C30.2479 113.547 29.9658 112.98 30.1414 112.456C30.3171 111.933 30.8839 111.651 31.4075 111.826L30.7715 113.722ZM50.5798 117.812L51.5735 117.7L50.5798 117.812ZM49.268 118.872L48.95 119.82L49.268 118.872ZM50.5849 106.321L50.4028 107.304L50.5849 106.321ZM49.4091 107.416L50.4028 107.304L49.4091 107.416ZM75.8179 112.011L50.4028 107.304L50.7671 105.338L76.1821 110.045L75.8179 112.011ZM50.4028 107.304L51.5735 117.7L49.586 117.923L48.4154 107.528L50.4028 107.304ZM48.95 119.82L30.7715 113.722L31.4075 111.826L49.586 117.923L48.95 119.82ZM51.5735 117.7C51.7371 119.153 50.3366 120.285 48.95 119.82L49.586 117.923L51.5735 117.7ZM50.4028 107.304L48.4154 107.528C48.2651 106.193 49.4466 105.093 50.7671 105.338L50.4028 107.304Z"
                        fill="#307B26"
                    />
                    <animated.path
                        style={bodySpring}
                        d="M73.5766 148.221C74.0278 148.54 74.1355 149.164 73.817 149.615C73.4986 150.066 72.8747 150.174 72.4234 149.855L73.5766 148.221ZM30.7886 132.574C30.3863 132.196 30.3671 131.563 30.7456 131.161C31.1241 130.759 31.7569 130.739 32.1591 131.118L30.7886 132.574ZM47.0723 144.571L48.0217 144.885L47.0723 144.571ZM45.4375 144.986L44.7522 145.714L45.4375 144.986ZM51.8816 134.136L51.305 134.953L51.8816 134.136ZM50.3556 134.639L51.305 134.953L50.3556 134.639ZM72.4234 149.855L51.305 134.953L52.4582 133.318L73.5766 148.221L72.4234 149.855ZM51.305 134.953L48.0217 144.885L46.1228 144.257L49.4061 134.325L51.305 134.953ZM44.7522 145.714L30.7886 132.574L32.1591 131.118L46.1228 144.257L44.7522 145.714ZM48.0217 144.885C47.5627 146.274 45.8173 146.716 44.7522 145.714L46.1228 144.257L48.0217 144.885ZM51.305 134.953L49.4061 134.325C49.8276 133.05 51.361 132.544 52.4582 133.318L51.305 134.953Z"
                        fill="#307B26"
                    />
                    <animated.path
                        style={bodySpring}
                        opacity="0.3"
                        d="M0.432344 124.275C-0.0223331 123.961 -0.136774 123.339 0.176733 122.884C0.490242 122.429 1.11298 122.315 1.56766 122.628L0.432344 124.275ZM20.297 136.757L20.8647 135.934L20.297 136.757ZM0.4543 97.7911L19.8172 85.1817L20.9086 86.8577L1.5457 99.467L0.4543 97.7911ZM26 88.5337V134.287H24V88.5337H26ZM19.7294 137.58L0.432344 124.275L1.56766 122.628L20.8647 135.934L19.7294 137.58ZM26 134.287C26 137.511 22.3829 139.41 19.7294 137.58L20.8647 135.934C22.1915 136.849 24 135.899 24 134.287H26ZM19.8172 85.1817C22.478 83.449 26 85.3584 26 88.5337H24C24 86.946 22.239 85.9913 20.9086 86.8577L19.8172 85.1817Z"
                        fill="#567284"
                    />
                    <animated.path
                        style={bodySpring}
                        d="M401.269 62.1646C400.808 61.861 400.188 61.989 399.884 62.4504C399.581 62.9117 399.709 63.5318 400.17 63.8354L401.269 62.1646ZM431.478 96.2685C431.977 96.5045 432.574 96.291 432.81 95.7917C433.045 95.2923 432.832 94.6963 432.333 94.4603L431.478 96.2685ZM414.358 85.499L413.719 84.73L414.358 85.499ZM414.57 87.1721L414.143 88.0762L414.57 87.1721ZM422.312 77.2069L421.763 78.0423L422.312 77.2069ZM422.402 78.8113L421.763 78.0423L422.402 78.8113ZM400.17 63.8354L421.763 78.0423L422.862 76.3715L401.269 62.1646L400.17 63.8354ZM421.763 78.0423L413.719 84.73L414.997 86.268L423.041 79.5803L421.763 78.0423ZM414.143 88.0762L431.478 96.2685L432.333 94.4603L414.997 86.268L414.143 88.0762ZM413.719 84.73C412.594 85.665 412.82 87.4514 414.143 88.0762L414.997 86.268L413.719 84.73ZM421.763 78.0423L423.041 79.5803C424.074 78.7217 423.984 77.1097 422.862 76.3715L421.763 78.0423Z"
                        fill="#307B26"
                    />
                    <animated.path
                        style={bodySpring}
                        d="M389.818 110.045C389.275 110.146 388.916 110.667 389.017 111.21C389.117 111.753 389.639 112.112 390.182 112.011L389.818 110.045ZM435.228 113.722C435.752 113.547 436.034 112.98 435.859 112.456C435.683 111.933 435.116 111.651 434.592 111.826L435.228 113.722ZM415.42 117.812L414.427 117.7L415.42 117.812ZM416.732 118.872L417.05 119.82L416.732 118.872ZM415.415 106.321L415.597 107.304L415.415 106.321ZM416.591 107.416L415.597 107.304L416.591 107.416ZM390.182 112.011L415.597 107.304L415.233 105.338L389.818 110.045L390.182 112.011ZM415.597 107.304L414.427 117.7L416.414 117.923L417.585 107.528L415.597 107.304ZM417.05 119.82L435.228 113.722L434.592 111.826L416.414 117.923L417.05 119.82ZM414.427 117.7C414.263 119.153 415.663 120.285 417.05 119.82L416.414 117.923L414.427 117.7ZM415.597 107.304L417.585 107.528C417.735 106.193 416.553 105.093 415.233 105.338L415.597 107.304Z"
                        fill="#307B26"
                    />
                    <animated.path
                        style={bodySpring}
                        d="M392.423 148.221C391.972 148.54 391.865 149.164 392.183 149.615C392.501 150.066 393.125 150.174 393.577 149.855L392.423 148.221ZM435.211 132.574C435.614 132.196 435.633 131.563 435.254 131.161C434.876 130.759 434.243 130.739 433.841 131.118L435.211 132.574ZM418.928 144.571L417.978 144.885L418.928 144.571ZM420.562 144.986L421.248 145.714L420.562 144.986ZM414.118 134.136L414.695 134.953L414.118 134.136ZM415.644 134.639L414.695 134.953L415.644 134.639ZM393.577 149.855L414.695 134.953L413.542 133.318L392.423 148.221L393.577 149.855ZM414.695 134.953L417.978 144.885L419.877 144.257L416.594 134.325L414.695 134.953ZM421.248 145.714L435.211 132.574L433.841 131.118L419.877 144.257L421.248 145.714ZM417.978 144.885C418.437 146.274 420.183 146.716 421.248 145.714L419.877 144.257L417.978 144.885ZM414.695 134.953L416.594 134.325C416.172 133.05 414.639 132.544 413.542 133.318L414.695 134.953Z"
                        fill="#307B26"
                    />
                    <animated.path
                        style={bodySpring}
                        opacity="0.3"
                        d="M465.568 124.275C466.022 123.961 466.137 123.339 465.823 122.884C465.51 122.429 464.887 122.315 464.432 122.628L465.568 124.275ZM445.703 136.757L445.135 135.934L445.703 136.757ZM465.546 97.7911L446.183 85.1817L445.091 86.8577L464.454 99.467L465.546 97.7911ZM440 88.5337V134.287H442V88.5337H440ZM446.271 137.58L465.568 124.275L464.432 122.628L445.135 135.934L446.271 137.58ZM440 134.287C440 137.511 443.617 139.41 446.271 137.58L445.135 135.934C443.809 136.849 442 135.899 442 134.287H440ZM446.183 85.1817C443.522 83.449 440 85.3584 440 88.5337H442C442 86.946 443.761 85.9913 445.091 86.8577L446.183 85.1817Z"
                        fill="#567284"
                    />
                    <defs>
                        <filter
                            id="filter0_d"
                            x="92"
                            y="0"
                            width="282"
                            height="282"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </animated.div>
            <div className={classes.stepper}>
                <animated.div className={classes.hr} style={colorSpring} />
                {question.possible_answers.map((answer, idx) => {
                    return (
                        <div className={classes.oneStep} key={idx}>
                            <animated.div className={classes.circle} style={colorSpring} />
                        </div>
                    );
                })}
                <animated.div
                    className={classes.answerbullete}
                    {...bind()}
                    style={Object.assign({}, bulleteSpring, {
                        transform: xy.interpolate((x, y) => `translateX(${x}px)`),
                    })}
                >
                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.429249 13.8761L6.48492 7.95136L0.429248 1.99389C-0.389086 1.20829 0.952982 -0.133781 1.77132 0.684553L8.67806 7.39489C8.97266 7.68949 8.97266 8.18049 8.67806 8.44236L1.77132 15.2182C0.952983 16.0038 -0.389085 14.6617 0.429249 13.8761Z"
                            fill="white"
                        />
                    </svg>
                </animated.div>
                <animated.div className={classes.animationbullete} style={bulletAnimationSpring}>
                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.429249 13.8761L6.48492 7.95136L0.429248 1.99389C-0.389086 1.20829 0.952982 -0.133781 1.77132 0.684553L8.67806 7.39489C8.97266 7.68949 8.97266 8.18049 8.67806 8.44236L1.77132 15.2182C0.952983 16.0038 -0.389085 14.6617 0.429249 13.8761Z"
                            fill="white"
                        />
                    </svg>
                </animated.div>
            </div>
            <div className={classes.steppertext}>
                <Typography className={classes.helptext} style={{ color: `${question.props.color}` }}>
                    never happened
                </Typography>
                <div style={{ flexGrow: 1 }}></div>
                <Typography className={classes.helptext} style={{ color: `${question.props.color}` }}>
                    5 times or more
                </Typography>
            </div>
            {!isMobile && (
                <Typography style={{ color: "#c3c3c3", opacity: 0.7 }} className={classes.slideText}>
                    Slide to your answer
                </Typography>
            )}
        </div>
    );
}
