import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import MyBisRouter from "./Router";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<MyBisRouter />, document.getElementById("root"));
{
	// document.body
	// 	.querySelector(
	// 		'div[style*="!important;padding:7px 15px !important;font-size:14px !important;font-family:arial !important;color:#fff !important;display:inline-block !important;transform:translate3d(0,0,0) !important;opacity:1 !important;height:auto !important;width:auto !important;zoom:1 !important;margin:auto !important;border:none !important;visibility:visible !important;clip-path:none !important;"]'
	// 	)
	// .remove();
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
